import React from 'react';
import {BrowserRouter as Router,Link, Switch,Route} from 'react-router-dom';
import Menu from '../Menus/Menus';


/*class Faq extends React.Component*/const Faq =(props) =>{
    
        return(
                <div className="faq">
                    <div className="faq-banner">
                        <img className='faq-banner-image' src={props.faqBanner} alt="Frequently Asked Questions"></img>
                        {/*<h1 className='faq-banner-title'>Frequently Asked Questions</h1>*/}
                    </div>
                    <div className="faq-question">
                        <h2 className="faq-question-title">How does Unlimited work? </h2>
                        <p className="faq-question-text">Unlimited features do not have a lifetime limit, they are just limited at the amount of work we can handle at any given time, 
                            for example if you as for 4 new features that are unrelated to each other we will ask you what features are the most important 
                            so that we can work on them one at a time to make sure they get implemented as soon as each of them are finished. If we need to 
                            do a never seen before feature some research may be involved and it may take some extra time.</p> 
                    </div>

                    <div className="faq-question">
                        <h2 className="faq-question-title">How long before my website goes live? </h2>
                        <p className="faq-question-text">Between 1 and 4 weeks for Basic and Social plans, It will all depend on how proactive you are on reviewing content and the 
                        complexity of your website. Cash Lovers plans with databases and payment integrations may take a little longer if you have not set up your merchant accounts already.</p>
                    </div>

                    <div className="faq-question">
                        <h2 className="faq-question-title">Do you offer one time services? </h2>
                        <p className="faq-question-text">Yes, <Link className="faq-question-link" to="/Contact/Contact.js">Give us a call</Link> so we can give you a custom pricing and time frame for your needs.
                        
                        </p>
                    </div>
                    <div className="faq-question">
                        <h2 className="faq-question-title"> What is the minimum price for one time services ? </h2>
                        <p className="faq-question-text">
                            Our one time services start at $499, but promotions and coupons can make this less depending on what we are running at the moment.
                        </p>
                    </div>
                    <div className="faq-question">
                        <h2 className="faq-question-title">Where are you located ? </h2>
                        <p className="faq-question-text">
                            We are located in Tampa, Florida in the US.
                        </p>
                    </div>
                    <div className="faq-question">
                        <h2 className="faq-question-title">Do you outsource overseas ? </h2>
                        <p className="faq-question-text">We do not, We train our own members in the US and contract them to deliver amazing results. If you are interested in becoming part of our team <Link className="faq-question-link" to="/Contact/Contact.js">Contact us</Link>. <br></br> With that said we do not discriminate anyone for any reason including but not limited to Gender, Sexual orientation, Color, Race, Disability, etc...</p>
                    </div>

                    <div className="faq-question">
                        <h2 className="faq-question-title">Where are you located ? </h2>
                        <p className="faq-question-text">
                            We are located in Tampa, Florida in the US.
                        </p>
                    </div>
                {/** Mold for more FAQS
                    <div className="faq-question">
                        <h2 className="faq-question-title"> Your Question here </h2>
                        <p className="faq-question-text">
                            Your answer here.
                        </p>
                    </div>
                */}


                </div>
        );
    
}

export default Faq;
/* NOTES ************
    What is the catch to Unlimited ? 
    turn around time?
    contact form?
    one time project start fee ?
    Do you outsource overseas ? no
    Do I need my content ready ?
    Do you cut corners ? 
    Are designs pre made templates ?
    Are websites



*/