import React from 'react';
import {BrowserRouter as Router,Link, Switch,Route} from 'react-router-dom';

const vib = (props) =>{
    return(
        <div className="VIB">
            <h2 className="VIB-title">VIB Monthly Membership Plans</h2>
            <h3 className="VIB-subtitle">The First Step to Running Your Business Better</h3>
            
            <div className="VIB-container">
                <div className="VIB-plan ">
                <div className="VIB-plan-image VIB-plan-background-green"><div className="VIB-plan-medal-text">VIB</div></div>
                        <div className="VIB-plan-content">
                        <h2 className="VIB-plan-title"> Remote Tech-Support</h2>
                        <ul className="VIB-plan-description">
                            {/**<li className="VIB-plan-description-item Orange">VIB Starter</li>
                            <li className="VIB-plan-description-item Plus"> + </li>
                            <li className="VIB-plan-description-item Silver">VIB Marketing & Web</li>
                            <li className="VIB-plan-description-item Plus"> + </li>
                            **/}
                            {/** 
                            <li className="VIB-plan-description-item">Online Payment Processing Integration*</li>
                            <li className="VIB-plan-description-item">Custom Website Features*</li>
                            <li className="VIB-plan-description-item">Database Integration</li>
                            <li className="VIB-plan-description-item">Advanced SEO</li>
                            <li className="VIB-plan-description-item">Unlimited Pages</li>
                            */}
                            
                            <li className="VIB-plan-description-item">Tech-Support Via</li>
                            <br></br>
                            <li className="VIB-plan-description-item Orange">Text</li>
                            <li className="VIB-plan-description-item Orange">Whatsapp</li>
                            <li className="VIB-plan-description-item Orange">Skype</li>
                            <li className="VIB-plan-description-item Orange">FaceTime</li>
                            <li className="VIB-plan-description-item Orange">E-mail</li>
                            
                        </ul>
                        <div className="VIB-plan-description-hosting">
                                Need hosting?<br/> 
                                <a  href="https://www.hostg.xyz/SH2dX"><img className="Hostinger" src={props.hostingerImg}/></a>
                            </div>
                        </div>
                        <form action="./Checkout/Checkout.php" method="post">
                        <input type="hidden" name="plan" value="0" />
                        <button className="VIB-plan-button" value="submit">$199/month</button>

                        </form>
                    
                </div>
                <div className="VIB-plan">
                    <div className="VIB-plan-image VIB-plan-background-silver"><div className="VIB-plan-medal-text">VIB</div></div>
                        <div className="VIB-plan-content">
                        <h2 className="VIB-plan-title">Marketing & Web</h2>
                        <ul className="VIB-plan-description">
                            <li className="VIB-plan-description-item Orange"> VIB Starter</li>
                            <li className="VIB-plan-description-item Plus"> + </li>
                            <li className="VIB-plan-description-item"> Social Media Marketing</li>
                            <li className="VIB-plan-description-item"> Business Listings</li>
                            <li className="VIB-plan-description-item"> Unlimited Stock Images</li>
                            <li className="VIB-plan-description-item"> Included Copywriting </li>
                            <li className="VIB-plan-description-item"> Multilingual features (English and Spanish)
                            </li>
                            <br></br>
                            <li className="VIB-plan-description-item Silver"> Website Included Upgrades </li>
                            <br></br>
                            <li className="VIB-plan-description-item">Advanced SEO</li>
                            <li className="VIB-plan-description-item">Online Payment Processing Integration*</li>
                            <li className="VIB-plan-description-item">Custom Website Features*</li>
                            <li className="VIB-plan-description-item">Advanced SEO</li>
                            <li className="VIB-plan-description-item">Unlimited Pages</li>

                        </ul>
                        <div className="VIB-plan-description-hosting">
                            Need hosting?<br/> 
                            <a  href="https://www.hostg.xyz/SH2dX"><img className="Hostinger" src={props.hostingerImg}/></a>
                        </div>
                        
                         {/*<h6 className="VIB-plan-disclaimer">*Only design, printed materials are not included 
                        in membership besides low quality samples **up to 3 different social media sites. 5 
                        weekly and unique posts in each site.***Replacement/removal of broken links, optimization
                         of keywords used throughout website pages.****Unlimited Stock Images not available for 
memberships based sites. </h6>*/}
                        </div>
                        
                        <form action="./Checkout/Checkout.php" method="post">
                            <input type="hidden" name="plan" value="1" />
                        <button className="VIB-plan-button" value="submit">$799/month</button>

                        </form>
                        {/*<Link className="VIB-plan-button" to="/Checkout/Checkout.html" onClick={() => props.CheckoutInput(799)}>$799/month</Link>*/}
                </div>
                <div className="VIB-plan last">
                <div className="VIB-plan-image VIB-plan-background-orange"><div className="VIB-plan-medal-text">VIB</div></div>
                        <div className="VIB-plan-content">
                        <h2 className="VIB-plan-title">Starter</h2>
                        <ul className="VIB-plan-description">
                            <li className="VIB-plan-description-item">Web Design</li>
                            <li className="VIB-plan-description-item">Web Maintenance and alterations</li>
                            <li className="VIB-plan-description-item">Logo Design and Changes</li>
                            <li className="VIB-plan-description-item">Mobile Responsive Design</li>
                            <li className="VIB-plan-description-item">Professional Business e-mails</li>
                            <li className="VIB-plan-description-item">Source Files</li>
                            <li className="VIB-plan-description-item">Accounts Login Info</li>
                            <li className="VIB-plan-description-item">Complete deployment</li>
                            <li className="VIB-plan-description-item">Google Analytics</li>
                            <li className="VIB-plan-description-item">Heatmaps and Video Feedback</li>
                            <li className="VIB-plan-description-item">Paper Stock Design(Presentation Cards, Paper advertising, Menus, Postcards)</li>
                        </ul>
                        <div className="VIB-plan-description-hosting">
                            Need hosting?<br/> 
                            <a  href="https://www.hostg.xyz/SH2dX"><img className="Hostinger" src={props.hostingerImg}/></a>
                        </div>
                        {/*<h6 className="VIB-plan-disclaimer">*Unlimited valid for one domain only, if more than one there may be extra charges. **Only design, printed materials are not included 
                        in membership besides low quality samples.***Powered by Hotjar API, included free plan,
    client may have to pay extra for extra features.</h6>*/}
                        </div>
                        <form action="./Checkout/Checkout.php" method="post">
                            <input type="hidden" name="plan" value="2" />
                        <button className="VIB-plan-button" value="submit">$599/month</button>

                        </form>
                </div>
            </div>
        </div>
    )
}

export default vib;