import React from 'react';

const whatsapp = (props) =>{
    return(
        
        <div className="whatsapp">
            
            <a className="whatsapp-button" href="https://wa.me/17868017389?text=Hi!%20%F0%9F%91%8B%20I%20am%20interested%20in%20your%20Unlimited%20Memberships"><img className="whatsapp-img" src={props.whatsappImg} alt="whatsapp image"></img> <p className="whatsapp-text">Questions?</p></a>
        </div>
    )
}

export default whatsapp;