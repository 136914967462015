import React from 'react';

const offers =(props)=>{
    return(
        <div className="offers">
            
           <div className="offers-text">
                <h2 className="offers-title offers-cursive">{props.planName}</h2>
                <h2 className="offers-title">${props.planPrice}.00<sub>/Mo</sub></h2>
                
                <h4 className="offers-subtitle">{props.expDate}</h4>
                {/**<h4 className="offers-subtitle">Use coupon "{props.coupon}" at checkout</h4>*/}
                <form action="./Checkout/Checkout.php" method="post">
                <input type="hidden" name="plan" value="3"></input>
                <button className="offers-subtitle-btn">Reedem Now</button>
                </form>

                {props.details}
            </div>
            {/*<a className={"button"} onClick={props.link}>Explore Offers</a>*/}

                {/*<div className="offers-overlay"></div>*
                
    <a className={"button"} onClick={props.link}> </a>*/}
                <div className="offers-background"></div>
        </div>
    );
}

export default offers;