import React from 'react';
import { BrowserRouter as Router,Link,Switch } from 'react-router-dom';

/*
let x = "";
let clicked = false;
*/
const menus =(props) => {
   
    return (
    <div id="menu_top">
        <Link to={"/"}><img className="menu_top-logo" src={props.logo}></img></Link>
            <div>
            <a className="menu_top-ActionBTN" href="tel:7868017389"><b>(786)801-7389</b></a>
            <div id="menu_side" /*onClick={props.menuHandler}*/>
                {/*<div className="menu_side-group">
                <div id="menu_side-topBar" className={props.TopBarAnimation}></div>
                <div id="menu_side-middleBar" className={props.MiddleBarAnimation}></div>
                <div id="menu_side-bottomBar" className={props.BottomBarAnimation}></div>
    </div>*/}
            {/*<div className="menuBtn">{props.menu}</div>*/}
            <a className="login" href="https://www.website-hosting.cloud/cpanel"><img className="side_Menu-list_Img" alt="Lock" src={props.lockImg}></img>Login</a>
            </div>
        </div>
        {/*<div id="side_menu" className={"side_Menu-hide " + props.x}>
            <div className="side_Menu-list">
                {/*<Link className="side_Menu-list_Items" to="#">Our Process</Link>
                <Link className="side_Menu-list_Items" to="#">About us</Link>*/}
                {/*<a className="side_Menu-list_Items" href="/customer_login.php"><img className="side_Menu-list_Img" alt="Lock" src={props.lockImg}></img>Customer Login</a>
            </div>
            </div>*/}
    </div>);
}
   /* 
const menuShow = () =>{
    if(clicked === true){
        x="side_Menu-off";
        console.log("clicked");
        clicked = false;
    }
    else if(clicked === false){
        x="side_Menu-on";
        console.log("clicked");
        clicked = true;
    }
    
  }*/
/*window.addEventListener('DOMContentLoaded', (event) => {

    const menuButton = document.getElementById('menu_side');
    const sideMenu = document.getElementById('side_menu');
    
    const menuShow = () =>{
        sideMenu.style.classList.add('side_Menu-on');
    }
    menuButton.addEventListener('click', menuShow);
});*/




export default menus;