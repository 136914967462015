import React from 'react';
import {BrowserRouter as Router,Link, Switch} from 'react-router-dom';
const index = [
    "AGREEMENT TO TERMS",
    "INTELLECTUAL PROPERTY RIGHTS",
    "USER REPRESENTATIONS",
    "USER REGISTRATION",
    "MARKETPLACE OFFERINGS",
    "PURCHASE AND PAYMENT",
    "REFUNDS POLICY",
    "PROHIBITED ACTIVITIES",
    "USER GENERATED CONTRIBUTIONS",
    "CONTRIBUTION LICENSE",
    "GUIDELINES FOR REVIEWS",
    "SOCIAL MEDIA",
    "SUBMISSIONS",
    "THIRD-PARTY WEBSITES AND CONTENT",
    "SITE MANAGEMENT",
    "PRIVACY POLICY",
    "TERM AND TERMINATION",
    "MODIFICATIONS AND INTERRUPTIONS",
    "GOVERNING LAW",
    "DISPUTE RESOLUTION",
    "CORRECTIONS",
    "DISCLAIMER",
    "LIMITATIONS OF LIABILITY",
    "INDEMNIFICATION",
    "USER DATA",
    "ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES",
    "CALIFORNIA USERS AND RESIDENTS",
    "MISCELLANEOUS",
    "CONTACT US"
]
const Conditions = () =>{

    return(
        <div>
            <div>
            <div className="conditions">
            <div className="conditions-wrapper">
                <h1 className="conditions-title">TERMS OF USE</h1>
                <h4 className="conditions-date">Last Updated March 26, 2020</h4>

                {/*<p className="conditions-text"><b>Please read this conditions policy carefully as it will help you make informed decisions about sharing your personal information with us.</b></p>
                <div className="conditions-index">
                    <h2 className="conditions-index-title">Table of Contents</h2>
                    <ol className="conditions-index-list">
                        <li className="conditions-index-list-item"><a onClick={() => scrollToAnchor("index1")}>{index[0]}</a></li>
                        <li className="conditions-index-list-item"><a onClick={() => scrollToAnchor("index2")}>{index[1]}</a></li>
                        <li className="conditions-index-list-item"><a onClick={() => scrollToAnchor("index3")}>{index[2]}</a></li>
                        <li className="conditions-index-list-item"><a onClick={() => scrollToAnchor("index4")}>{index[3]}</a></li>
                        <li className="conditions-index-list-item"><a onClick={() => scrollToAnchor("index5")}>{index[4]}</a></li>
                        <li className="conditions-index-list-item"><a onClick={() => scrollToAnchor("index6")}>{index[5]}</a></li>
                        <li className="conditions-index-list-item"><a onClick={() => scrollToAnchor("index7")}>{index[6]}</a></li>
                        <li className="conditions-index-list-item"><a onClick={() => scrollToAnchor("index8")}>{index[7]}</a></li>
                        <li className="conditions-index-list-item"><a onClick={() => scrollToAnchor("index9")}>{index[8]}</a></li>
                        <li className="conditions-index-list-item"><a onClick={() => scrollToAnchor("index10")}>{index[9]}</a></li>
                        <li className="conditions-index-list-item"><a onClick={() => scrollToAnchor("index11")}>{index[10]}</a></li>
                        <li className="conditions-index-list-item"><a onClick={() => scrollToAnchor("index12")}>{index[11]}</a></li>
                        <li className="conditions-index-list-item"><a onClick={() => scrollToAnchor("index13")}>{index[12]}</a></li>
                        <li className="conditions-index-list-item"><a onClick={() => scrollToAnchor("index14")}>{index[13]}</a></li>
                    </ol>
                </div>
    */}
                <div className="conditions-subwrapper">
                    <div id="index1" className="anchor">
                        <h2 className="conditions-item-title">{index[0]}</h2>

                        <p className="conditions-text">These Terms of Use constitute a legally binding agreement made between you, whether 
                        personally or on behalf of an entity (“you”) and Mixael Contreras Corp. ("<b>Company</b>", “<b>we</b>”, “<b>us</b>”, or “<b>our</b>”), concerning
                         your access to and use of the <Link className="privacy-links" to="/">https://mixaelcontreras.com</Link> website as well as any other media form, media channel, 
                         mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). The
                          Site provides an online marketplace for the following goods, products, and/or services: Marketing, Software 
                          Development, Game Development, Design Services. (the “Marketplace Offerings”). In order to help make the Site a 
                          secure environment for the purchase and sale of Marketplace Offerings, all users are required to accept and comply
                           with these Terms of Use. You agree that by accessing the Site and/or the Marketplace Offerings, you have read, 
                           understood, and agree to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE,
                            THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND/OR THE MARKETPLACE OFFERINGS AND YOU MUST DISCONTINUE 
                            USE IMMEDIATELY.</p>

                        <p className="conditions-text">Supplemental terms and conditions or documents that may be posted on the Site from time
                         to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make 
                         changes or modifications to these Terms of Use at any time and for any reason. We will alert you about any changes by
                          updating the “Last updated” date of these Terms of Use, and you waive any right to receive specific notice of each 
                          such change. It is your responsibility to periodically review these Terms of Use to stay informed of updates. You 
                          will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised 
                          Terms of Use by your continued use of the Site after the date such revised Terms of Use are posted.</p>

                        <p className="conditions-text">The information provided on the Site is not intended for distribution to or use by 
                            any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or 
                            regulation or which would subject us to any registration requirement within such jurisdiction or country. 
                            Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and 
                            are solely responsible for compliance with local laws, if and to the extent local laws are applicable. </p>

                        <p className="conditions-text">The Site is not tailored to comply with industry-specific regulations (Health 
                        Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), 
                        so if your interactions would be subjected to such laws, you may not use this Site. You may not use the Site in a 
                        way that would violate the Gramm-Leach-Bliley Act (GLBA).</p>

                        <p className="conditions-text">The Site is intended for users who are at least 18 years old. Persons under the age
                         of 18 are not permitted to use or register for the Site or use the Marketplace Offerings.</p>
                    </div>

                        
                                                                                {/* INDEX 2 STARTS  */}
                    <div id="index2" className="anchor">
                        <h2 className="conditions-item-title">{index[1]}</h2>

                        <p className="conditions-text">Unless otherwise indicated, the Site and the Marketplace Offerings are our proprietary
                         property and all source code, databases, functionality, software, website designs, audio, video, text, photographs,
                          and graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and logos contained therein
                           (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and
                            various other intellectual property rights and unfair competition laws of the United States, international copyright
                             laws, and international conventions. The Content and the Marks are provided on the Site “AS IS” for your information
                              and personal use only. Except as expressly provided in these Terms of Use, no part of the Site or the Marketplace 
                              Offerings and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly 
                              displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial
                               purpose whatsoever, without our express prior written permission.</p>

                        <p className="conditions-text">Provided that you are eligible to use the Site, you are granted a limited license to access
                         and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access 
                         solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the
                          Content and the Marks.</p>

                        {/*<ul className="conditions-list">
                            <li><p className="conditions-list-text"><b>To facilitate account creation and logon process.</b> If you choose to link your account with 
                            us to a third party account (such as your Google or Facebook account), we use the information you allowed us to collect from those 
                            third parties to facilitate account creation and logon process for the performance of the contract. See the section below headed 
                            "<a className="conditions-links" href="#">{index[5].slice(3)}</a>" for further information.</p></li>
                            <li><p className="conditions-list-text"> <b>To send you marketing and promotional communications.</b> We and/or our third party marketing partners
                             may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You
                              can opt-out of our marketing emails at any time (see the "<a className="conditions-links" href="#">{index[9].slice(3)}</a>" below).</p></li>
                            <li><p className="conditions-list-text"><b>To send administrative information to you.</b> We may use your personal information to send you product, service 
                                and new feature information and/or information about changes to our terms, conditions, and policies.</p></li>
                            <li><p className="conditions-list-text"><b>Fulfill and manage your orders.</b> We may use your information to fulfill and manage your orders, payments, returns, 
                            and exchanges made through the Services.  </p></li>
                            <li><p className="conditions-list-text"><b>To post testimonials.</b> We post testimonials on our Services that may contain personal information. Prior to posting
                             a testimonial, we will obtain your consent to use your name and testimonial. If you wish to update, or delete your testimonial, please contact us at 
                             <a className="conditions-links" href="mailto:conditions@mixaelcontreras.com">conditions@mixaelcontreras.com</a> and be sure to include your name, testimonial location, and contact information.  </p></li>
                            <li><p className="conditions-list-text"><b>Deliver targeted advertising to you.</b> We may use your information to develop and display content and advertising 
                            (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.  </p></li>
                            <li><p className="conditions-list-text"><b>Administer prize draws and competitions.</b> We may use your information to administer prize draws and competitions 
                            when you elect to participate in competitions.  </p></li>
                            <li><p className="conditions-list-text"><b>Request Feedback.</b> We may use your information to request feedback and to contact you about your use of our Services.  </p></li>
                            <li><p className="conditions-list-text"><b>To manage user accounts.</b> We may use your information for the purposes of managing our account and keeping it in 
                            working order.</p></li>
                            <li><p className="conditions-list-text"><b>To deliver services to the user.</b> We may use your information to provide you with the requested service.  </p></li>
                            <li><p className="conditions-list-text"><b>To respond to user inquiries/offer support to users.</b> We may use your information to respond to your inquiries 
                            and solve any potential issues you might have with the use of our Services.  </p></li>
                            <li><p className="conditions-list-text"><b>For other Business Purposes.</b> We may use your information for other Business Purposes, such as data analysis, 
                            identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Services, products, marketing 
                            and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users 
                            and does not include personal information. We will not use identifiable personal information without your consent.  </p></li>
                        </ul>*/}
                    </div>
                                                                             {/* INDEX 2 ENDS  */}
                                                                                         {/* INDEX 3 STARTS  */}
                    <div id="index3" className="anchor">
                        <h2 className="conditions-item-title">{index[2]}</h2>

                        <p className="conditions-text">By using the Site or the Marketplace Offerings, you represent and warrant that:(1) all 
                        registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of 
                        such information and promptly update such registration information as necessary; (3) you have the legal capacity and you 
                        agree to comply with these Terms of Use; (4) you are not a minor in the jurisdiction in which you reside; (5) you will not
                         access the Site or the Marketplace Offerings through automated or non-human means, whether through a bot, script or 
                         otherwise; (6) you will not use the Site for any illegal or unauthorized purpose; and (7) your use of the Site or the 
                         Marketplace Offerings will not violate any applicable law or regulation.</p>

                        <p className="conditions-text">If you provide any information that is untrue, inaccurate, not current, or incomplete, we 
                        have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any 
                        portion thereof).</p>

                        <p className="conditions-text">You may not use the Site or the Marketplace Offerings for any illegal or unauthorized 
                        purpose nor may you, in the use of Marketplace Offerings, violate any laws. Among unauthorized Marketplace Offerings are 
                        the following: intoxicants of any sort; illegal drugs or other illegal products; alcoholic beverages; games of chance; 
                        and pornography or graphic adult content, images, or other adult products. Postings of any unauthorized products or 
                        content may result in immediate termination of your account and a lifetime ban from use of the Site.</p>

                        <p className="conditions-text">We are a service provider and make no representations as to the safety, effectiveness, 
                        adequacy, accuracy, availability, prices, ratings, reviews, or legality of any of the information contained on the Site
                         or the Marketplace Offerings displayed or offered through the Site. You understand and agree that the content of the 
                         Site does not contain or constitute representations to be reasonably relied upon, and you agree to hold us harmless from
                          any errors, omissions, or misrepresentations contained within the Site’s content. We do not endorse or recommend any 
                          Marketplace Offerings and the Site is provided for informational and advertising purposes only.</p>

                        
{/*
                        <ul className="conditions-list">
                            <li><p className="conditions-list-text"><b>Consent:</b> We may process your data if you have given us specific consent to use 
                            your personal information in a specific purpose. </p></li>

                            <li><p className="conditions-list-text"><b>Legitimate Interests: </b>We may process your data when it is reasonably necessary 
                            to achieve our legitimate business interests. </p></li>

                            <li><p className="conditions-list-text"><b>Performance of a Contract: </b>Where we have entered into a contract with you, we 
                            may process your personal information to fulfill the terms of our contract. </p></li>

                            <li><p className="conditions-list-text"><b>Legal Obligations: </b>We may disclose your information where we are legally
                             required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or 
                             legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national
                              security or law enforcement requirements). </p></li>

                            <li><p className="conditions-list-text"><b>Vital Interests: </b>We may disclose your information where we believe it is 
                            necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations 
                            involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are 
                            involved. </p></li>

                            <p  className="conditions-text">More specifically, we may need to process your data or share your personal information in the following situations:</p>

                            <li><p className="conditions-list-text"><b>Vendors, Consultants and Other Third-Party Service Providers. </b>We may share your data with third party vendors,
                             service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work.
                              Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow 
                              selected third parties to use tracking technology on the Services, which will enable them to collect data about how you interact with the Services
                               over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better 
                               understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for 
                               their promotional purposes.  </p></li>

                            <li><p className="conditions-list-text"><b>Business Transfers. </b>We may share or transfer your information in connection with, or during negotiations
                             of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. </p></li>

                            <li><p className="conditions-list-text"><b>Third-Party Advertisers. </b> We may use third-party advertising companies to serve ads when you visit the Services.
                             These companies may use information about your visits to our Website(s) and other websites that are contained in web cookies and other tracking technologies
                              in order to provide advertisements about goods and services of interest to you. </p></li>
                        
                        
                        </ul>*/}
                        
                                                                                        {/* INDEX 4 STARTS  */}
                    </div>
                    <div id="index4" className="anchor">
                        <h2 className="conditions-item-title">{index[3]}</h2>
                        <p className="conditions-text">You may be required to register with the Site in order to access the Marketplace Offerings.
                         You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve
                          the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username
                           is inappropriate, obscene, or otherwise objectionable.</p>

                                                                                            {/* INDEX 5 STARTS  */}
                    </div>
                    <div id="index5" className="anchor">
                        <h2 className="conditions-item-title">{index[4]}</h2>
                        <p className="conditions-text">We reserve the right to limit the quantities of the Marketplace Offerings offered or available
                         on the Site. All descriptions or pricing of the Marketplace Offerings are subject to change at any time without notice, at 
                         our sole discretion. We reserve the right to discontinue any Marketplace Offerings at any time for any reason. We do not 
                         warrant that the quality of any of the Marketplace Offerings purchased by you will meet your expectations or that any errors
                          in the Site will be corrected.</p>
                                                                                                        {/* INDEX 6 STARTS  */}
                    </div>
                    <div id="index6" className="anchor">
                        <h2 className="conditions-item-title">{index[5]}</h2>
                         <p className="conditions-text">We accept the following forms of payment: </p>
                         <ul className="conditions-list-bar">
                            <li><p className="conditions-list-text">Visa</p></li>
                            <li><p className="conditions-list-text">Mastercard</p></li>
                            <li><p className="conditions-list-text">American Express</p></li>
                            <li><p className="conditions-list-text">Discover</p></li>
                        </ul>
                         <p className="conditions-text">You agree to provide current, complete, and accurate purchase and account information for all
                          purchases of the Marketplace Offerings made via the Site. You further agree to promptly update account and payment information,
                           including email address, payment method, and payment card expiration date, so that we can complete your transactions and 
                           contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We may change prices at any
                            time. All payments shall be in U.S. dollars.</p>

                         <p className="conditions-text">You agree to pay all charges at the prices then in effect for your purchases and any applicable 
                         shipping fees, and you authorize us to charge your chosen payment provider for any such amounts upon placing your order. If your 
                         order is subject to recurring charges, then you consent to our charging your payment method on a recurring basis without requiring
                          your prior approval for each recurring charge, until such time as you cancel the applicable order. We reserve the right to correct
                           any errors or mistakes in pricing, even if we have already requested or received payment.</p>

                         <p className="conditions-text">We reserve the right to refuse any order placed through the Site. We may, in our sole discretion, 
                         limit or cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed by or 
                         under the same customer account, the same payment method, and/or orders that use the same billing or shipping address. We reserve 
                         the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, or distributors.</p>
                         
                                                                                                    {/* INDEX 7 STARTS  */}
                    </div>
                    <div id="index7" className="anchor">
                        <h2 className="conditions-item-title">{index[6]}</h2>

                        <p className="conditions-text">Thank you for your purchase. We hope you are happy with our service. However, if you are not completely
                         satisfied with your purchase, you may request a full refund, exchange or cancellation. Please see below for more information on our return policy. <br/></p>
                         <ul className="conditions-list">
                             <p className="conditions-text">If you are a VIB plan holder with <u>monthly</u> recurring payments, you have the right to the following: </p>
                             <li className="conditions-list-text extra-margin-list"><b>Full refund guarantee:</b> <br/>If requested within 7 days after 1st payment, consecutive months
                              do not qualify for any refunds. Only applicable to first time customers.</li>
                             <li className="conditions-list-text extra-margin-list"><b>Exchange to a different VIB plan:</b> <br/>If requested at any time, leftover balances will be 
                             credited into the following months, 
                             unless this change was made within 7 days of first payment in that case we can refund the leftover balance upon request. Negative balances will be due 
                             immediately.</li>
                             
                             <p className="conditions-text">If you are a VIB plan holder with <u>yearly</u> recurring payments, you have the right to the following: </p>
                             <li className="conditions-list-text extra-margin-list"><b>Full refund guarantee:</b> <br/>If requested within 30 days of 1st payment, consecutive years do not qualify for any refunds. 
                             Only applicable to first time customers.</li>
                             <li className="conditions-list-text extra-margin-list"><b>Exchange to a different VIB plan:</b> <br/>If requested at any time, leftover balances will be credited into the following year, 
                             unless this change was made within 15 days of first payment in that case we can refund the leftover balance upon request. Negative balances will be due 
                             immediately.</li>
                             <p className="conditions-text"><b>Clients with Individual Projects:</b><br/>
                             The return policy is included in our "One-time Project Service agreement". An additional copy can be sent upon request.</p>
                         </ul>

                        <p className="conditions-text"></p>

                                                                                                    {/* INDEX 8 STARTS  */}
                    </div>
                    <div id="index8" className="anchor">
                        <h2 className="conditions-item-title">{index[7]}</h2>
                        

                        <p className="conditions-text">You may not access or use the Site for any purpose other than that for which we make the Site 
                        available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or 
                        approved by us. </p>
                        <p className="conditions-text">As a user of the Site, you agree not to:<br/><br/></p>

                        <ol className="conditions-index-list-numbered">
                            <li className="conditions-index-list-item">Systematically retrieve data or other content from the Site to create or compile,
                             directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>

                            <li className="conditions-index-list-item"> Make any unauthorized use of the Marketplace Offerings, including collecting 
                            usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or 
                            creating user accounts by automated means or under false pretenses.</li>

                            <li className="conditions-index-list-item">Use the Site to advertise or offer to sell goods and services.</li>
                            <li className="conditions-index-list-item">Trick, defraud, or mislead us and other users, especially in any attempt to learn
                             sensitive account information such as user passwords.</li>

                            <li className="conditions-index-list-item">Make improper use of our support services or submit false reports of abuse or misconduct.</li>

                            <li className="conditions-index-list-item">Engage in any automated use of the system, such as using scripts to send comments 
                            or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>

                            <li className="conditions-index-list-item">Interfere with, disrupt, or create an undue burden on the Site or the networks or 
                            services connected to the Site.</li>

                            <li className="conditions-index-list-item">Attempt to impersonate another user or person or use the username of another user.</li>
                            <li className="conditions-index-list-item">Use any information obtained from the Site in order to harass, abuse, or harm another person.</li>
                            <li className="conditions-index-list-item">Use the Marketplace Offerings as part of any effort to compete with us or otherwise
                             use the Site and/or the Content for any revenue-generating endeavor or commercial enterprise.</li>

                            <li className="conditions-index-list-item">Decipher, decompile, disassemble, or reverse engineer any of the software comprising
                             or in any way making up a part of the Site.</li>

                            <li className="conditions-index-list-item">Attempt to bypass any measures of the Site designed to prevent or restrict access to
                             the Site, or any portion of the Site.</li>

                            <li className="conditions-index-list-item">Harass, annoy, intimidate, or threaten any of our employees or agents engaged in 
                            providing any portion of the Marketplace Offerings to you.</li>

                            <li className="conditions-index-list-item">Delete the copyright or other proprietary rights notice from any Content.</li>

                            <li className="conditions-index-list-item">Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, 
                            JavaScript, or other code.</li>

                            <li className="conditions-index-list-item"> Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, 
                            or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that 
                            interferes with any party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes 
                            with the use, features, functions, operation, or maintenance of the Marketplace Offerings.</li>

                            <li className="conditions-index-list-item">Upload or transmit (or attempt to upload or to transmit) any material that acts as
                             a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange
                              formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive 
                              collection mechanisms” or “pcms”).</li>

                            <li className="conditions-index-list-item">Except as may be the result of standard search engine or Internet browser usage, use,
                             launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or
                              offline reader that accesses the Site, or using or launching any unauthorized script or other software.</li>

                            <li className="conditions-index-list-item">Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</li>

                            <li className="conditions-index-list-item">Use the Site in a manner inconsistent with any applicable laws or regulations.</li>

                            <li className="conditions-index-list-item">Engage in unauthorized framing of or linking to the Site.</li>

                            <li className="conditions-index-list-item">Circumvent, disable, or otherwise interfere with security-related features of the Site, 
                            including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or 
                            the Content contained therein.</li>
                        </ol>

                        <p className="conditions-text"></p>
                        <p className="conditions-text"></p>
                        <p className="conditions-text"></p>
                        <p className="conditions-text"></p>
                                                                                                    {/* INDEX 9 STARTS  */}
                    </div>
                    <div id="index9" className="anchor">
                        <h2 className="conditions-item-title">{index[8]}</h2>
                        <p className="conditions-text">The Site does not offer users to submit or post content. We may provide you with the opportunity to create,
                         submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Site, including but not
                          limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material 
                          (collectively, "Contributions"). Contributions may be viewable by other users of the Site and through third-party websites. As such, any
                           Contributions you transmit may be treated in accordance with the Site Privacy Policy. When you create or make available any Contributions,
                            you thereby represent and warrant that:<br/><br/></p>

                            <ol className="conditions-index-list-numbered">
                                <li className="conditions-index-list-item">The creation, distribution, transmission, public display, or performance, and the accessing,
                                 downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the 
                                 copyright, patent, trademark, trade secret, or moral rights of any third party.</li>

                                <li className="conditions-index-list-item">You are the creator and owner of or have the necessary licenses, rights, consents, releases,
                                 and permissions to use and to authorize us, the Site, and other users of the Site to use your Contributions in any manner contemplated
                                  by the Site and these Terms of Use.</li>

                                <li className="conditions-index-list-item">You have the written consent, release, and/or permission of each and every identifiable 
                                individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable 
                                inclusion and use of your Contributions in any manner contemplated by the Site and these Terms of Use.</li>

                                <li className="conditions-index-list-item">Your Contributions are not false, inaccurate, or misleading.</li>
                                <li className="conditions-index-list-item">Your Contributions are not unsolicited or unauthorized advertising, promotional materials, 
                                pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.</li>

                                <li className="conditions-index-list-item">Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, 
                                slanderous, or otherwise objectionable (as determined by us).</li>

                                <li className="conditions-index-list-item">Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>

                                <li className="conditions-index-list-item">Your Contributions do not advocate the violent overthrow of any government or incite, 
                                encourage, or threaten physical harm against another.</li>

                                <li className="conditions-index-list-item">Your Contributions do not violate any applicable law, regulation, or rule.</li>

                                <li className="conditions-index-list-item">Your Contributions do not violate the privacy or publicity rights of any third party.</li>

                                <li className="conditions-index-list-item">Your Contributions do not contain any material that solicits personal information from anyone 
                                under the age of 18 or exploits people under the age of 18 in a sexual or violent manner.</li>

                                <li className="conditions-index-list-item">Your Contributions do not violate any applicable law concerning child pornography, or otherwise
                                 intended to protect the health or well-being of minors.</li>

                                <li className="conditions-index-list-item">Your Contributions do not include any offensive comments that are connected to race, national 
                                origin, gender, sexual preference, or physical handicap.</li>

                                <li className="conditions-index-list-item">Your Contributions do not otherwise violate, or link to material that violates, any provision 
                                of these Terms of Use, or any applicable law or regulation.</li>
                             </ol>

                             <p className="conditions-text">Any use of the Site or the Marketplace Offerings in violation of the foregoing violates these Terms of Use 
                             and may result in, among other things, termination or suspension of your rights to use the Site and the Marketplace Offerings.</p>
                                                                                                    {/* INDEX 10 STARTS  */}
                    </div>
                    <div id="index10" className="anchor">
                        <h2 className="conditions-item-title">{index[9]}</h2>
                        <p className="conditions-text">You and the Site agree that we may access, store, process, and use any information and personal data that you 
                        provide following the terms of the Privacy Policy and your choices (including settings).</p>
                        
                        <p className="conditions-text">By submitting suggestions or other feedback regarding the Site, you agree that we can use and share such 
                        feedback for any purpose without compensation to you.</p>
                      
                        <p className="conditions-text">We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions
                         and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or
                          representations in your Contributions provided by you in any area on the Site. You are solely responsible for your Contributions to the Site
                           and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.</p>

                                                                                                    {/* INDEX 11 STARTS  */}
                    </div>

                    <div id="index11" className="anchor">
                        <h2 className="conditions-item-title">{index[10]}</h2>

                        <p className="conditions-text">We may provide you areas on the Site to leave reviews or ratings. When posting a review, you must comply with the
                         following criteria: (1) you should have firsthand experience with the person/entity being reviewed; (2) your reviews should not contain offensive
                          profanity, or abusive, racist, offensive, or hate language; (3) your reviews should not contain discriminatory references based on religion, 
                          race, gender, national origin, age, marital status, sexual orientation, or disability; (4) your reviews should not contain references to illegal 
                          activity; (5) you should not be affiliated with competitors if posting negative reviews; (6) you should not make any conclusions as to the legality 
                          of conduct; (7) you may not post any false or misleading statements; and (8) you may not organize a campaign encouraging others to post reviews, 
                          whether positive or negative. </p>
                        <p className="conditions-text">We may accept, reject, or remove reviews in our sole discretion. We have absolutely no obligation to screen reviews or
                         to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not endorsed by us, and do not necessarily represent our
                          opinions or the views of any of our affiliates or partners. We do not assume liability for any review or for any claims, liabilities, or losses 
                          resulting from any review. By posting a review, you hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully-paid, assignable,
                           and sublicensable right and license to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all content relating
                            to reviews.</p>
                                                                                                    {/* INDEX 12 STARTS  */}
                                                                         
                    </div>
                    <div id="index12" className="anchor">
                        <h2 className="conditions-item-title">{index[11]}</h2>
                        <p className="conditions-text">As part of the functionality of the Site, you may link your account with online accounts you have with third-party service
                         providers (each such account, a “Third-Party Account”) by either: (1) providing your Third-Party Account login information through the Site; or (2) 
                         allowing us to access your Third-Party Account, as is permitted under the applicable terms and conditions that govern your use of each Third-Party Account.
                          You represent and warrant that you are entitled to disclose your Third-Party Account login information to us and/or grant us access to your Third-Party 
                          Account, without breach by you of any of the terms and conditions that govern your use of the applicable Third-Party Account, and without obligating us to
                           pay any fees or making us subject to any usage limitations imposed by the third-party service provider of the Third-Party Account. By granting us access 
                           to any Third-Party Accounts, you understand that (1) we may access, make available, and store (if applicable) any content that you have provided to and 
                           stored in your Third-Party Account (the “Social Network Content”) so that it is available on and through the Site via your account, including without 
                           limitation any friend lists and (2) we may submit to and receive from your Third-Party Account additional information to the extent you are notified when 
                           you link your account with the Third-Party Account. Depending on the Third-Party Accounts you choose and subject to the privacy settings that you have set
                            in such Third-Party Accounts, personally identifiable information that you post to your Third-Party Accounts may be available on and through your account
                             on the Site. Please note that if a Third-Party Account or associated service becomes unavailable or our access to such Third-Party Account is terminated 
                             by the third-party service provider, then Social Network Content may no longer be available on and through the Site. You will have the ability to disable 
                             the connection between your account on the Site and your Third-Party Accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE
                              PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS. We make no effort to
                               review any Social Network Content for any purpose, including but not limited to, for accuracy, legality, or non-infringement, and we are not responsible 
                               for any Social Network Content. You acknowledge and agree that we may access your email address book associated with a Third-Party Account and your 
                               contacts list stored on your mobile device or tablet computer solely for purposes of identifying and informing you of those contacts who have also 
                               registered to use the Site. You can deactivate the connection between the Site and your Third-Party Account by contacting us using the contact information
                                below or through your account settings (if applicable). We will attempt to delete any information stored on our servers that was obtained through such 
                                Third-Party Account, except the username and profile picture that become associated with your account.</p>

                                                                                                    {/* INDEX 13 STARTS  */}                                                                         
                    </div>


                    <div id="index13" className="anchor">
                        <h2 className="conditions-item-title">{index[12]}</h2>
                        <p className="conditions-text">You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or other information regarding the 
                        Site or the Marketplace Offerings ("Submissions") provided by you to us are non-confidential and shall become our sole property. We shall own exclusive rights, 
                        including all intellectual property rights, and shall be entitled to the unrestricted use and dissemination of these Submissions for any lawful purpose, commercial
                         or otherwise, without acknowledgment or compensation to you. You hereby waive all moral rights to any such Submissions, and you hereby warrant that any such 
                         Submissions are original with you or that you have the right to submit such Submissions. You agree there shall be no recourse against us for any alleged or 
                         actual infringement or misappropriation of any proprietary right in your Submissions.</p>
                                                                                                    {/* INDEX 14 STARTS  */}                                                                         
                    </div>

                    <div id="index13" className="anchor">
                        <h2 className="conditions-item-title">{index[13]}</h2>
                        <p className="conditions-text">The Site may contain (or you may be sent via the Site or the Marketplace Offerings) links to other websites ("Third-Party 
                        Websites") as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or 
                        items belonging to or originating from third parties ("Third-Party Content"). Such Third-Party Websites and Third-Party Content are not investigated, monitored,
                         or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third Party Websites accessed through the Site or any Third-Party
                          Content posted on, available through, or installed from the Site, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, or 
                          other policies of or contained in the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting the use or installation of any 
                          Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the Site and access the Third-Party
                           Websites or to use or install any Third-Party Content, you do so at your own risk, and you should be aware these Terms of Use no longer govern. You should review
                            the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from the Site or relating to any 
                            applications you use or install from the Site. Any purchases you make through Third-Party Websites will be through other websites and from other companies, and
                             we take no responsibility whatsoever in relation to such purchases which are exclusively between you and the applicable third party. You agree and acknowledge
                              that we do not endorse the products or services offered on Third-Party Websites and you shall hold us harmless from any harm caused by your purchase of such 
                              products or services. Additionally, you shall hold us harmless from any losses sustained by you or harm caused to you relating to or resulting in any way from
                               any Third-Party Content or any contact with Third-Party Websites. </p>
                                                                                                    {/* INDEX 15 STARTS  */}                                                                         
                    </div>

                    <div id="index13" className="anchor">
                        <h2 className="conditions-item-title">{index[14]}</h2>
                        <p className="conditions-text">We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms of Use; (2) take appropriate
                         legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement 
                         authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically 
                         feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or 
                         otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Site in a manner designed 
                         to protect our rights and property and to facilitate the proper functioning of the Site and the Marketplace Offerings.</p>

                                                                                                    {/* INDEX 16 STARTS  */}                                                                         
                    </div>

                    <div id="index13" className="anchor">
                        <h2 className="conditions-item-title">{index[15]}</h2>
                        <p className="conditions-text">We care about data privacy and security. By using the Site or the Marketplace Offerings, you agree to be bound by our 
                        Privacy Policy posted on the Site, which is incorporated into these Terms of Use. Please be advised the Site and the Marketplace Offerings are hosted in the 
                        United States. If you access the Site or the Marketplace Offerings from any other region of the world with laws or other requirements governing personal data 
                        collection, use, or disclosure that differ from applicable laws in the United States, then through your continued use of the Site, you are transferring your data 
                        to the United States, and you agree to have your data transferred to and processed in the United States. </p>
                        
                        <p className="conditions-text">You can find our privacy policy by clicking <Link className="conditions-links" to='/Privacy/Privacy.js'>here</Link>.</p>

                                                                                                    {/* INDEX 17 STARTS  */}                                                                         
                    </div>

                    <div id="index13" className="anchor">
                        <h2 className="conditions-item-title">{index[16]}</h2>
                        <p className="conditions-text">These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF 
                        THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE AND THE MARKETPLACE 
                        OFFERINGS (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, 
                        WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE AND THE 
                        MARKETPLACE OFFERINGS OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION. </p>

                        <p className="conditions-text">If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under 
                        your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or 
                        suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>

                                                                                                    {/* INDEX 18 STARTS  */}                                                                         
                    </div>

                    <div id="index13" className="anchor">
                        <h2 className="conditions-item-title">{index[17]}</h2>
                        <p className="conditions-text">We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole 
                        discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or 
                        part of the Marketplace Offerings without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, 
                        or discontinuance of the Site or the Marketplace Offerings.</p>

                        <p className="conditions-text">We cannot guarantee the Site and the Marketplace Offerings will be available at all times. We may experience hardware, 
                        software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the right to change, 
                        revise, update, suspend, discontinue, or otherwise modify the Site or the Marketplace Offerings at any time or for any reason without notice to you. You agree 
                        that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site or the Marketplace Offerings 
                        during any downtime or discontinuance of the Site or the Marketplace Offerings. Nothing in these Terms of Use will be construed to obligate us to maintain and 
                        support the Site or the Marketplace Offerings or to supply any corrections, updates, or releases in connection therewith.</p>

                                                                                                    {/* INDEX 19 STARTS  */}                                                                         
                    </div>

                    <div id="index13" className="anchor">
                        <h2 className="conditions-item-title">{index[18]}</h2>
                        <p className="conditions-text">These Terms of Use and your use of the Site and the Marketplace Offerings are governed by and construed in accordance 
                        with the laws of the State of Florida applicable to agreements made and to be entirely performed within the State of Florida, without regard to its conflict 
                        of law principles.</p>

                                                                                                    {/* INDEX 20 STARTS  */}                                                                         
                    </div>

                    <div id="index13" className="anchor">
                        <h2 className="conditions-item-title">{index[19]}</h2>
                        <h3 className="conditions-item-subtitle">Informal Negotiations</h3>
                        <p className="conditions-text">To expedite resolution and control the cost of any dispute, controversy, or claim related to these Terms of Use (each a "Dispute" 
                        and collectively, the "Disputes") brought by either you or us (individually, a "Party" and collectively, the "Parties"), the Parties agree to first attempt to 
                        negotiate any Dispute (except those Disputes expressly provided below) informally for at least ninety (90) days before initiating arbitration. Such informal 
                        negotiations commence upon written notice from one Party to the other Party.</p>
                        <h3 className="conditions-item-subtitle">Binding Arbitration</h3>
                        <p className="conditions-text">If the Parties are unable to resolve a Dispute through informal negotiations, the Dispute (except those Disputes expressly 
                        excluded below) will be finally and exclusively resolved through binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO 
                        SUE IN COURT AND HAVE A JURY TRIAL. The arbitration shall be commenced and conducted under the Commercial Arbitration Rules of the American Arbitration 
                        Association ("AAA") and, where appropriate, the AAA’s Supplementary Procedures for Consumer Related Disputes ("AAA Consumer Rules"), both of which are 
                        available at the AAA website: <a className="conditions-links" href="https://www.adr.org/" target="blank">www.adr.org</a>. Your arbitration fees and your share of arbitrator compensation shall be governed by the AAA Consumer Rules and, 
                        where appropriate, limited by the AAA Consumer Rules. The arbitration may be conducted in person, through the submission of documents, by phone, or online. 
                        The arbitrator will make a decision in writing, but need not provide a statement of reasons unless requested by either Party. The arbitrator must follow 
                        applicable law, and any award may be challenged if the arbitrator fails to do so. Except where otherwise required by the applicable AAA rules or applicable law,
                         the arbitration will take place in Hillsborough, Florida. Except as otherwise provided herein, the Parties may litigate in court to compel arbitration, stay 
                         proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the award entered by the arbitrator.</p>

                        <p className="conditions-text">If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute shall be commenced or prosecuted in the state 
                        and federal courts located in Hillsborough, Florida, and the Parties hereby consent to, and waive all defenses of lack of personal jurisdiction, and forum non 
                        conveniens with respect to venue and jurisdiction in such state and federal courts. Application of the United Nations Convention on Contracts for the 
                        International Sale of Goods and the the Uniform Computer Information Transaction Act (UCITA) is excluded from these Terms of Use.</p>

                        <p className="conditions-text">If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within 
                        that portion of this provision found to be illegal or unenforceable, and such Dispute shall be decided by a court of competent jurisdiction within the courts 
                        listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.</p>

                        <h3 className="conditions-item-subtitle">Restrictions</h3>
                        <p className="conditions-text">The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent 
                        permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action 
                        basis or to utilize class action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of 
                        the general public or any other persons.</p>

                        <h3 className="conditions-item-subtitle">Exceptions to Informal Negotiations and Arbitration</h3>
                        <p className="conditions-text">The Parties agree that the following Disputes are not subject to the above provisions concerning informal negotiations and binding 
                        arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party; (b) any Dispute related 
                        to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim for injunctive relief. If this provision is found to be 
                        illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and 
                        such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the personal 
                        jurisdiction of that court.</p>

                                                                                                    {/* INDEX 21 STARTS  */}                                                                         
                    </div>

                    <div id="index13" className="anchor">
                        <h2 className="conditions-item-title">{index[20]}</h2>
                        <p className="conditions-text">There may be information on the Site that contains typographical errors, inaccuracies, or omissions that may relate to the Marketplace 
                        Offerings, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to 
                        change or update the information on the Site at any time, without prior notice.</p>
                                                                                                    {/* INDEX 22 STARTS  */}                                                                         
                    </div>

                    <div id="index13" className="anchor">
                        <h2 className="conditions-item-title">{index[21]}</h2>
                        <p className="conditions-text">THE SITE AND THE MARKETPLACE OFFERINGS ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR 
                        SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND THE 
                        MARKETPLACE OFFERINGS AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND 
                        NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE 
                        AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF 
                        ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL 
                        INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE OR THE MARKETPLACE OFFERINGS, (5) ANY 
                        BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND 
                        MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT 
                        WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR 
                        ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION 
                        BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE 
                        YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</p>

                                                                                                    {/* INDEX 23 STARTS  */}                                                                         
                    </div>

                    <div id="index13" className="anchor">
                        <h2 className="conditions-item-title">{index[22]}</h2>
                        <p className="conditions-text">IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, 
                        EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE OR THE 
                        MARKETPLACE OFFERINGS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU 
                        FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US  DURING THE one 
                        (1) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING OR $999.00 USD. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR 
                        THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE 
                        ADDITIONAL RIGHTS.</p>
                                                                                                    {/* INDEX 24 STARTS  */}                                                                         
                    </div>

                    <div id="index13" className="anchor">
                        <h2 className="conditions-item-title">{index[23]}</h2>
                        <p className="conditions-text">You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, 
                        partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due 
                        to or arising out of: (1) use of the Marketplace Offerings; (2) breach of these Terms of Use; (3) any breach of your representations and warranties set forth in these 
                        Terms of Use; (4) your violation of the rights of a third party, including but not limited to intellectual property rights; or (5) any overt harmful act toward any other 
                        user of the Site or the Marketplace Offerings with whom you connected via the Site. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the 
                        exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We 
                        will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.</p>

                                                                                                    {/* INDEX 25 STARTS  */}                                                                         
                    </div>

                    <div id="index13" className="anchor">
                        <h2 className="conditions-item-title">{index[24]}</h2>
                        <p className="conditions-text">We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the Marketplace Offerings, 
                        as well as data relating to your use of the Marketplace Offerings. Although we perform regular routine backups of data, you are solely responsible for all data that 
                        you transmit or that relates to any activity you have undertaken using the Marketplace Offerings. You agree that we shall have no liability to you for any loss or 
                        corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.</p>

                                                                                                    {/* INDEX 26 STARTS  */}                                                                         
                    </div>

                    <div id="index13" className="anchor">
                        <h2 className="conditions-item-title">{index[25]}</h2>
                        <p className="conditions-text">Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive 
                        electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the 
                        Site, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, 
                        AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or requirements 
                        under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic 
                        records, or to payments or the granting of credits by any means other than electronic means. </p>

                                                                                                    {/* INDEX 27 STARTS  */}                                                                         
                    </div>

                    <div id="index13" className="anchor">
                        <h2 className="conditions-item-title">{index[26]}</h2>
                        <p className="conditions-text">If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer 
                        Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at 
                         <a className="conditions-links" href="tel:8009525210"> (800) 952-5210</a> or <a className="conditions-links" href="tel:9164451254">(916) 445-1254</a>.</p>
                                                                                                    {/* INDEX 28 STARTS  */}                                                                         
                    </div>

                    <div id="index13" className="anchor">
                        <h2 className="conditions-item-title">{index[27]}</h2>
                        <p className="conditions-text">These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the Marketplace Offerings constitute
                         the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a 
                         waiver of such right or provision. These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to 
                         others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any
                          provision or part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed 
                          severable from these Terms of Use and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment 
                          or agency relationship created between you and us as a result of these Terms of Use or use of the Marketplace Offerings. You agree that these Terms of Use will not 
                          be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and 
                          the lack of signing by the parties hereto to execute these Terms of Use.</p>

                                                                                                    {/* INDEX 29 STARTS  */}                                                                         
                    </div>
            
                    <div id="index14" className="anchor">
                        <h2 className="conditions-item-title">{index[28]}</h2>
                        <p className="conditions-text">In order to resolve a complaint regarding the Site or the Marketplace Offerings or to receive further information regarding use of the 
                        Site or the Marketplace Offerings, please contact us at: <br/></p>

                        <p className="conditions-text">If you have questions or comments about this policy, you may email us at  or by post to:</p>
                        <p className="conditions-text">
                            <b>
                            Mixael Contreras Corp.<br/>
                            1723 Waikiki Way<br/>
                            Tampa, FL 33619<br/>
                            United States<br/>
                            Phone: <a className="conditions-links" href="tel:7868017389">(+1)7868017389</a><br/>
                            E-mail: <a className="conditions-links" href="mailto:we-care@mixaelcontreras.com">we-care@mixaelcontreras.com</a>
                            </b>
                            </p>

                    </div>
                </div>
            </div>
        </div>

        </div>
        </div>
    );
   
}
export default Conditions;
