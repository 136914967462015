import React from 'react';

const separator =(props)=>{
    return(
        <div id={props.link} className="div-separator">
            
        </div>
    );
}

export default separator;