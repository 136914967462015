import React from 'react';
import Router from 'react-router-dom';

let otherChecked = false;
const contact = (props) => {
    let handler=()=>{
        if(otherChecked == false){
            document.getElementById('other').style.animation = "pushedBtn 500ms 1 forwards ease-out";
            document.getElementById('contacts-sales-group').style.display = "none";
            document.getElementById('contacts-support-group').style.display = "none";
            document.getElementById('contacts-careers-group').style.display = "none";
            document.getElementById('email-Input').style.display = "none";
            document.getElementById('message-Input').style.display = "none";
            document.getElementById('submit-Input').style.display = "none";
            document.getElementById('other-info').style.display = "block";
            otherChecked = true;
        }
        else{
            document.getElementById('other').style.animation = "none";
            document.getElementById('contacts-sales-group').style.display = "";
            document.getElementById('contacts-support-group').style.display = "";
            document.getElementById('contacts-careers-group').style.display = "";
            document.getElementById('email-Input').style.display = "";
            document.getElementById('message-Input').style.display = "";
            document.getElementById('submit-Input').style.display = "";
            document.getElementById('other-info').style.display = "none";
            otherChecked = false;
        }
        
    }
    return(
        <div className="contacts">
            <div className="contacts-left"></div>
            <div className="contacts-right">
                
                                                                            {/*STARTS FORM*/}
                <div className="contacts-form-outsider-wrap">
                    <form className="contacts-form" method="POST" action="contactHandler.php">
                        <div className="contacts-form-wrapper">
                            <h2 className="contacts-form-title">What can we help with?</h2>
                            <div className="contacts-form-radioGroup">
                                <div id="contacts-sales-group" className="contacts-form-labelAndBtn labelAndBtn_first">
                                    <input id="sales" className="contacts-radioBtn" type="radio" name="contact" value="sales"/>
                                    <label className="contacts-radioBtn-text" for="sales">Sales</label>
                                </div>
                                <div id="contacts-support-group" className="contacts-form-labelAndBtn">                            
                                    <input id="support" className="contacts-radioBtn" type="radio" name="contact" value="support"/>
                                    <label className="contacts-radioBtn-text" for="support">Support</label>
                                </div>
                                <div className="contacts-form-labelAndBtn">
                                    <input id="other" className="contacts-radioBtn" type="radio" name="contact" value="other" onClick={handler}/>
                                    <label className="contacts-radioBtn-text" for="other">Other</label>
                                </div>
                                <div id="contacts-careers-group" className="contacts-form-labelAndBtn labelAndBtn_last">
                                    <input id="careers" className="contacts-radioBtn" type="radio" name="contact" value="careers"/>
                                    <label className="contacts-radioBtn-text" for="careers">Careers</label>
                                </div>
                            </div>
                            <input id="email-Input" className="contacts-form-textInputs" type="text" name="email" placeholder="Your E-mail"/>
                            <textarea id="message-Input" className="contacts-form-textArea" type="text" name="message" placeholder="Your Message Goes Here..."/>
                            <input id="submit-Input" className="contacts-form-submitBtn" type="submit" value="Send Message"/>
                            <div id="other-info">
                                <div className="address"><b><img src={props.locationImage} alt="Our Address:"/>Headquarters</b><br/>Mixael Contreras Corp.<br/>1723 Waikiki Way<br/>Tampa, FL 33619<br/></div>
                                <div className="contacts-phoneLine"><img src={props.phoneImage} alt="Our Phone#:"/><p>786.801.7389 <br/></p></div>
                                <div className="contacts-emailLine"><img src={props.emailImage} alt="Our E-mail:"/><p>We-Care@mixaelcontreras.com</p></div>
                            </div>
                        </div>
                        
                        
                    </form>
                                                            {/*ENDS FORM*/}
                    
                </div>
            </div>
        </div>
    );
}

export default contact;