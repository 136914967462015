import React from 'react';
import {BrowserRouter as Router,Link, Switch,Route} from 'react-router-dom';
const match = "/Privacy/Privacy.js/";
const index = [
    "1. WHAT INFORMATION DO WE COLLECT?",
    "2. HOW DO WE USE YOUR INFORMATION?",

"3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?",

"4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?",

"5. DO WE USE GOOGLE MAPS?",

"6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?",

"7. HOW LONG DO WE KEEP YOUR INFORMATION?",

"8. HOW DO WE KEEP YOUR INFORMATION SAFE?",

"9. DO WE COLLECT INFORMATION FROM MINORS?",

"10. WHAT ARE YOUR PRIVACY RIGHTS?",

"11. CONTROLS FOR DO-NOT-TRACK FEATURES",

"12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?",

"13. DO WE MAKE UPDATES TO THIS POLICY?",

"14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?"
];
let scrollToAnchor = (property) =>{
    //console.log(property);
    if(property != null){
    let y = document.getElementById(property).offsetTop;

    return window.scrollTo(0,y);
    }
}

const privacy= (props)=>{
    return(
        <div className="privacy">
           {/**  <div className="privacy-banner">
                <div className="privacy-banner-wrapper">
                        <img className="privacy-banner-image" src={props.fingerprint} alt="Privacy is personal"></img>
                        <h5 className="privacy-banner-text">Privacy must be protected.</h5>
                </div>
                
            </div>*/}
            <div className="privacy-wrapper">
                <h1 className="privacy-title">PRIVACY POLICY</h1>
                <h4 className="privacy-date">Last Updated March 26, 2020</h4>
                <div className="privacy-subwrapper">
                <p className="privacy-text">Thank you for choosing to be part of our community at Mixael Contreras Corp. <b>
                     (“Company”, “we”, “us”, or “our”).</b> We are committed to protecting your personal
                      information and your right to privacy. If you have any questions or concerns 
                      about our policy, or our practices with regards to your personal information, 
                      please contact us at <a className="privacy-links" href="mailto:we-care@mixaelcontreras.com">we-care@mixaelcontreras.com</a>.</p>

                <p className="privacy-text">When you visit our website <Link className="privacy-links" to="/">https://mixaelcontreras.com</Link>, and use our services, you trust us with your
                     personal information. We take your privacy very seriously. In this privacy policy, we seek to explain 
                     to you in the clearest way possible what information we collect, how we use it and what rights you have 
                     in relation to it. We hope you take some time to read through it carefully, as it is important. If there 
                     are any terms in this privacy policy that you do not agree with, please discontinue use of our Sites and 
                     our services.</p>
                     
                <p className="privacy-text">This privacy policy applies to all information collected through our website 
                (such as  <Link className="privacy-links" to="/">https://mixaelcontreras.com</Link>), and/or any related 
                services, sales, marketing or events (we refer to them collectively in this privacy policy as the "<b>Services</b>").</p>

                <p className="privacy-text"><b>Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.</b></p>
                <div className="privacy-index">
                    <h2 className="privacy-index-title">Table of Contents</h2>
                    <ol className="privacy-index-list">
                        <li className="privacy-index-list-item"><a onClick={() => scrollToAnchor("index1")}>{index[0]}</a></li>
                        <li className="privacy-index-list-item"><a onClick={() => scrollToAnchor("index2")}>{index[1]}</a></li>
                        <li className="privacy-index-list-item"><a onClick={() => scrollToAnchor("index3")}>{index[2]}</a></li>
                        <li className="privacy-index-list-item"><a onClick={() => scrollToAnchor("index4")}>{index[3]}</a></li>
                        <li className="privacy-index-list-item"><a onClick={() => scrollToAnchor("index5")}>{index[4]}</a></li>
                        <li className="privacy-index-list-item"><a onClick={() => scrollToAnchor("index6")}>{index[5]}</a></li>
                        <li className="privacy-index-list-item"><a onClick={() => scrollToAnchor("index7")}>{index[6]}</a></li>
                        <li className="privacy-index-list-item"><a onClick={() => scrollToAnchor("index8")}>{index[7]}</a></li>
                        <li className="privacy-index-list-item"><a onClick={() => scrollToAnchor("index9")}>{index[8]}</a></li>
                        <li className="privacy-index-list-item"><a onClick={() => scrollToAnchor("index10")}>{index[9]}</a></li>
                        <li className="privacy-index-list-item"><a onClick={() => scrollToAnchor("index11")}>{index[10]}</a></li>
                        <li className="privacy-index-list-item"><a onClick={() => scrollToAnchor("index12")}>{index[11]}</a></li>
                        <li className="privacy-index-list-item"><a onClick={() => scrollToAnchor("index13")}>{index[12]}</a></li>
                        <li className="privacy-index-list-item"><a onClick={() => scrollToAnchor("index14")}>{index[13]}</a></li>
                    </ol>
                </div>

                    <div id="index1" className="anchor">
                        <h2 className="privacy-item-title">{index[0]}</h2>
                        <h3 className="privacy-item-subtitle">Personal information you disclose to us</h3>
                        <p className="privacy-text italic"><b>In Short:</b>  We collect personal information that you provide to us.</p>
                        <p className="privacy-text">We collect personal information that you voluntarily provide to us when registering
                         at the Services expressing an interest in obtaining information about us or our products and services, when 
                         participating in activities on the Services or otherwise contacting us.</p>

                        <p className="privacy-text">The personal information that we collect depends on the context of your interactions 
                        with us and the Services, the choices you make and the products and features you use. The personal information 
                        we collect can include the following:</p>

                        <p className="privacy-text"><b>Publicly Available Personal Information.</b> We collect first name, maiden name, 
                        last name, and nickname; current and former address; phone numbers; email addresses; business email; 
                        business phone number; social media; business name; ID; and other similar data. </p>

                        <p className="privacy-text"><b>Personal Information Provided by You.</b> We collect app usage; financial 
                        information (credit card number, purchase history, invoices); passwords; data collected from surveys; and
                         other similar data. </p>

                        <p className="privacy-text"><b>Payment Data.</b> We collect data necessary to process your payment if you 
                        make purchases, such as your payment instrument number (such as a credit card number), and the security code 
                        associated with your payment instrument. All payment data is stored by PaymentCloud and Electronic Merchant Systems.
                         You may find their privacy policy link(s) here: <a className="privacy-links" href="https://paymentcloudinc.com/privacy/">
                        https://paymentcloudinc.com/privacy</a> and <a className="privacy-links" href="https://www.emscorporate.com/privacypolicy/">
                        https://www.emscorporate.com/privacypolicy</a>.</p>

                        <p className="privacy-text"><b>Social Media Login Data.</b>  We may provide you with the option to register using social media
                         account details, like your Facebook, Twitter or other social media account. If you choose to register in this way, we will 
                         collect the Information described in the section called "
                         <a className="privacy-links" onClick={() => scrollToAnchor("index6")}>{index[5].slice(3)}</a>"</p>

                        <p className="privacy-text">All personal information that you provide to us must be true, complete and accurate, and you must
                         notify us of any changes to such personal information.</p>

                         <h3 className="privacy-item-subtitle">Information automatically collected</h3>
                        <p className="privacy-text italic"><b>In Short:</b>   Some information — such as IP address and/or browser and device characteristics 
                        — is collected automatically when you visit our Services.</p>

                        <p className="privacy-text">We automatically collect certain information when you visit, use or navigate the Services. This 
                        information does not reveal your specific identity (like your name or contact information) but may include device and usage 
                        information, such as your IP address, browser and device characteristics, operating system, language preferences, referring 
                        URLs, device name, country, location, information about how and when you use our Services and other technical information. 
                        This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics 
                        and reporting purposes.</p>

                        <p className="privacy-text">Like many businesses, we also collect information through cookies and similar technologies.</p>
                        <p className="privacy-text"><b>Online Identifiers.</b> We collect devices; cookie identifiers, or others such as the ones used for 
                        analytics and marketing; tools and protocols, such as IP (Internet Protocol) addresses; device's geolocation; and other 
                        similar data.</p>
                    </div>
                                                                                {/* INDEX 2 STARTS  */}
                    <div id="index2" className="anchor">
                        <h2 className="privacy-item-title">{index[1]}</h2>
                        <p className="privacy-text italic"><b>In Short:</b>  We process your information for purposes based on legitimate business interests, 
                        the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</p>

                        <p className="privacy-text">We use personal information collected via our Services for a variety of business purposes described below. We process your personal 
                        information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with 
                        you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next 
                        to each purpose listed below.</p>
                        <p className="privacy-text">We use the information we collect or receive:</p>
                        <ul className="privacy-list">
                            <li><p className="privacy-list-text"><b>To facilitate account creation and logon process.</b> If you choose to link your account with 
                            us to a third party account (such as your Google or Facebook account), we use the information you allowed us to collect from those 
                            third parties to facilitate account creation and logon process for the performance of the contract. See the section below headed 
                            "<a className="privacy-links" onClick={() => scrollToAnchor("index6")}>{index[5].slice(3)}</a>" for further information.</p></li>
                            <li><p className="privacy-list-text"> <b>To send you marketing and promotional communications.</b> We and/or our third party marketing partners
                             may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You
                              can opt-out of our marketing emails at any time (see the "<a className="privacy-links" onClick={() => scrollToAnchor("index6")}>{index[9].slice(3)}</a>" below).</p></li>
                            <li><p className="privacy-list-text"><b>To send administrative information to you.</b> We may use your personal information to send you product, service 
                                and new feature information and/or information about changes to our terms, conditions, and policies.</p></li>
                            <li><p className="privacy-list-text"><b>Fulfill and manage your orders.</b> We may use your information to fulfill and manage your orders, payments, returns, 
                            and exchanges made through the Services.  </p></li>
                            <li><p className="privacy-list-text"><b>To post testimonials.</b> We post testimonials on our Services that may contain personal information. Prior to posting
                             a testimonial, we will obtain your consent to use your name and testimonial. If you wish to update, or delete your testimonial, please contact us at 
                             <a className="privacy-links" href="mailto:privacy@mixaelcontreras.com">privacy@mixaelcontreras.com</a> and be sure to include your name, testimonial location, and contact information.  </p></li>
                            <li><p className="privacy-list-text"><b>Deliver targeted advertising to you.</b> We may use your information to develop and display content and advertising 
                            (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.  </p></li>
                            <li><p className="privacy-list-text"><b>Administer prize draws and competitions.</b> We may use your information to administer prize draws and competitions 
                            when you elect to participate in competitions.  </p></li>
                            <li><p className="privacy-list-text"><b>Request Feedback.</b> We may use your information to request feedback and to contact you about your use of our Services.  </p></li>
                            <li><p className="privacy-list-text"><b>To manage user accounts.</b> We may use your information for the purposes of managing our account and keeping it in 
                            working order.</p></li>
                            <li><p className="privacy-list-text"><b>To deliver services to the user.</b> We may use your information to provide you with the requested service.  </p></li>
                            <li><p className="privacy-list-text"><b>To respond to user inquiries/offer support to users.</b> We may use your information to respond to your inquiries 
                            and solve any potential issues you might have with the use of our Services.  </p></li>
                            <li><p className="privacy-list-text"><b>For other Business Purposes.</b> We may use your information for other Business Purposes, such as data analysis, 
                            identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Services, products, marketing 
                            and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users 
                            and does not include personal information. We will not use identifiable personal information without your consent.  </p></li>
                        </ul>
                    </div>
                                                                             {/* INDEX 2 ENDS  */}
                                                                                         {/* INDEX 3 STARTS  */}
                    <div id="index3" className="anchor">
                        <h2 className="privacy-item-title">{index[2]}</h2>
                        <p className="privacy-text italic"><b>In Short:</b>  We only share information with your consent, to comply with laws, to provide you with services, 
                        to protect your rights, or to fulfill business obligations.</p>

                        <p className="privacy-text">We may process or share data based on the following legal basis:</p>

                        <ul className="privacy-list">
                            <li><p className="privacy-list-text"><b>Consent:</b> We may process your data if you have given us specific consent to use 
                            your personal information in a specific purpose. </p></li>

                            <li><p className="privacy-list-text"><b>Legitimate Interests: </b>We may process your data when it is reasonably necessary 
                            to achieve our legitimate business interests. </p></li>

                            <li><p className="privacy-list-text"><b>Performance of a Contract: </b>Where we have entered into a contract with you, we 
                            may process your personal information to fulfill the terms of our contract. </p></li>

                            <li><p className="privacy-list-text"><b>Legal Obligations: </b>We may disclose your information where we are legally
                             required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or 
                             legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national
                              security or law enforcement requirements). </p></li>

                            <li><p className="privacy-list-text"><b>Vital Interests: </b>We may disclose your information where we believe it is 
                            necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations 
                            involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are 
                            involved. </p></li>

                            <p  className="privacy-text">More specifically, we may need to process your data or share your personal information in the following situations:</p>

                            <li><p className="privacy-list-text"><b>Vendors, Consultants and Other Third-Party Service Providers. </b>We may share your data with third party vendors,
                             service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work.
                              Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow 
                              selected third parties to use tracking technology on the Services, which will enable them to collect data about how you interact with the Services
                               over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better 
                               understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for 
                               their promotional purposes.  </p></li>

                            <li><p className="privacy-list-text"><b>Business Transfers. </b>We may share or transfer your information in connection with, or during negotiations
                             of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. </p></li>

                            <li><p className="privacy-list-text"><b>Third-Party Advertisers. </b> We may use third-party advertising companies to serve ads when you visit the Services.
                             These companies may use information about your visits to our Website(s) and other websites that are contained in web cookies and other tracking technologies
                              in order to provide advertisements about goods and services of interest to you. </p></li>
                        
                        
                        </ul>
                        
                                                                                        {/* INDEX 4 STARTS  */}
                    </div>
                    <div id="index4" className="anchor">
                        <h2 className="privacy-item-title">{index[3]}</h2>
                        <p className="privacy-text italic"><b>In Short:</b>  We may use cookies and other tracking technologies to collect and store your information.</p>
                        <p className="privacy-text">We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. 
                        Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.</p>

                                                                                            {/* INDEX 5 STARTS  */}
                    </div>
                    <div id="index5" className="anchor">
                        <h2 className="privacy-item-title">{index[4]}</h2>
                        <p className="privacy-text italic"><b>In Short:</b>  Yes, we use Google Maps for the purpose of providing better service.</p>
                        <p className="privacy-text">This website, mobile application, or Facebook application uses Google Maps APIs. You may find the Google Maps APIs Terms of
                             Service <a className="privacy-links" href="https://developers.google.com/maps/terms" target="blank">here</a>. To better understand Google’s Privacy 
                             Policy, please refer to this <a className="privacy-links" href="https://policies.google.com/privacy" target="blank">link</a>.</p>

                        <p className="privacy-text">By using our Maps API Implementation, you agree to be bound by Google’s Terms of Service. </p>
                                                                                                        {/* INDEX 6 STARTS  */}
                    </div>
                    <div id="index6" className="anchor">
                        <h2 className="privacy-item-title">{index[5]}</h2>
                        <p className="privacy-text italic"><b>In Short:</b>  If you choose to register or log in to our services using a social media account, we may have access
                         to certain information about you.</p>
                         <p className="privacy-text">Our Services offer you the ability to register and login using your third party social media account details (like your Facebook
                          or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile 
                          Information we receive may vary depending on the social media provider concerned, but will often include your name, e-mail address, friends list, profile 
                          picture as well as other information you choose to make public. </p>

                         <p className="privacy-text">We will use the information we receive only for the purposes that are described in this privacy policy or that are otherwise
                          made clear to you on the Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third 
                          party social media provider. We recommend that you review their privacy policy to understand how they collect, use and share your personal information, 
                          and how you can set your privacy preferences on their sites and apps.</p>
                         
                                                                                                    {/* INDEX 7 STARTS  */}
                    </div>
                    <div id="index7" className="anchor">
                        <h2 className="privacy-item-title">{index[6]}</h2>
                        <p className="privacy-text italic"><b>In Short:</b>  We keep your information for as long as necessary to fulfill the purposes outlined in this privacy
                         policy unless otherwise required by law.</p>

                        <p className="privacy-text">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, 
                        unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will
                         require us keeping your personal information for longer than 2 years past the start of idle period of the user's account.</p>

                        <p className="privacy-text">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it,
                         or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your 
                         personal information and isolate it from any further processing until deletion is possible.</p>

                                                                                                    {/* INDEX 8 STARTS  */}
                    </div>
                    <div id="index8" className="anchor">
                        <h2 className="privacy-item-title">{index[7]}</h2>
                        <p className="privacy-text italic"><b>In Short:</b>  We aim to protect your personal information through a system of organizational and technical security measures.</p>

                        <p className="privacy-text">We have implemented appropriate technical and organizational security measures designed to protect the security of any personal
                         information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect
                          your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the services within a 
                          secure environment.</p>
                                                                                                    {/* INDEX 9 STARTS  */}
                    </div>
                    <div id="index9" className="anchor">
                        <h2 className="privacy-item-title">{index[8]}</h2>
                        <p className="privacy-text italic"><b>In Short:</b>  We do not knowingly collect data from or market to children under 18 years of age.</p>
                        <p className="privacy-text">We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are
                         at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information
                          from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records.
                           If you become aware of any data we have collected from children under age 18, please contact us at <a className="privacy-links" href="mailto:privacy@mixaelcontreras.com">privacy@mixaelcontreras.com</a>.</p>
                                                                                                    {/* INDEX 10 STARTS  */}
                    </div>
                    <div id="index10" className="anchor">
                        <h2 className="privacy-item-title">{index[9]}</h2>
                        <p className="privacy-text italic"><b>In Short:</b> You may review, change, or terminate your account at any time.</p>
                        <p className="privacy-text">If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have 
                        the right to complain to your local data protection supervisory authority. You can find their contact details here: <a className="privacy-links" href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="blank">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.</p>
                        
                        <p className="privacy-text">If you have questions or comments about your privacy rights, you may email us at <a className="privacy-links" href="mailto:privacy@mixaelcontreras.com">privacy@mixaelcontreras.com</a>.</p>
                        <h3 className="privacy-item-subtitle">Account Information</h3>
                        <ul className="privacy-list">
                            <li>
                                <p className="privacy-list-text">Contact us using the contact information provided. </p>
                            </li>
                        </ul>
                        
                        <p className="privacy-text">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However,
                         some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with 
                         legal requirements.</p>

                        <p className="privacy-text"><u><b>Cookies and similar technologies:</b></u> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your
                         browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services. 
                         To opt-out of interest-based advertising by advertisers on our Services visit <a className="privacy-links" href="http://www.aboutads.info/choices/" target="blank">http://www.aboutads.info/choices/</a>.</p>
                        <p className="privacy-text"><u><b>Opting out of email marketing:</b></u> You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in 
                        the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list – however, we will still 
                        need to send you service-related emails that are necessary for the administration and use of your account. To otherwise opt-out, you may:</p>
                        
                        <ul className="privacy-list">
                            <li>
                                <p className="privacy-list-text">Contact us using the contact information provided. </p>
                            </li>
                        </ul>
                                                                                                    {/* INDEX 11 STARTS  */}
                    </div>


                    <div id="index11" className="anchor">
                        <h2 className="privacy-item-title">{index[10]}</h2>

                        <p className="privacy-text">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) 
                        feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored 
                        and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently
                         respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard
                          for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this 
                          privacy policy.</p>
                                                                                                    {/* INDEX 12 STARTS  */}
                                                                         
                    </div>
                    <div id="index12" className="anchor">
                        <h2 className="privacy-item-title">{index[11]}</h2>
                        <p className="privacy-text italic"><b>In Short:</b> Yes, if you are a resident of California, you are granted specific rights regarding 
                        access to your personal information.</p>

                        <p className="privacy-text">California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are 
                        California residents to request and obtain from us, once a year and free of charge, information about categories of personal information
                         (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we 
                         shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a 
                         request, please submit your request in writing to us using the contact information provided below.</p>

                        <p className="privacy-text">If you are under 18 years of age, reside in California, and have a registered account with the Services, you
                         have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please 
                         contact us using the contact information provided below, and include the email address associated with your account and a statement that
                          you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may 
                          not be completely or comprehensively removed from our systems.</p>

                                                                                                    {/* INDEX 13 STARTS  */}                                                                         
                    </div>


                    <div id="index13" className="anchor">
                        <h2 className="privacy-item-title">{index[12]}</h2>
                        <p className="privacy-text italic"><b>In Short:</b>  Yes, we will update this policy as necessary to stay compliant with relevant laws.</p>
                        <p className="privacy-text">We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised”
                         date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify
                          you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this 
                          privacy policy frequently to be informed of how we are protecting your information.</p>
                                                                                                    {/* INDEX 14 STARTS  */}                                                                         
                    </div>
                    <div id="index14" className="anchor">
                        <h2 className="privacy-item-title">{index[13]}</h2>
                        <p className="privacy-text italic">If you have questions or comments about this policy, you may email us at <a className="privacy-links" href="mailto:privacy@mixaelcontreras.com">privacy@mixaelcontreras.com</a> or by post to:</p>
                        <p className="privacy-text">
                            Mixael Contreras Corp.<br/>
                            1723 Waikiki Way<br/>
                            Tampa, FL 33619<br/>
                            United States<br/>
                            </p>

                    </div>
                    <div id="lastIndex" className="anchor">
                    <h2 className="privacy-item-title">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
                        <p className="privacy-text">Based on the laws of some countries, you may have the right to request access to the personal information we
                         collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal 
                         information, please submit a request form by clicking <Link className="privacy-links" to={'/Contact/Contact.js'}>here</Link>. We will respond to your request within 30 days.</p>

                    </div>
                    </div>
            </div>
        </div>
    );
}
export default privacy;