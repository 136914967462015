/* Importing React components */
import React, {Component} from 'react';
import {BrowserRouter  as BrowserRouter,Link,Route,Switch} from "react-router-dom";

/* Importing Custom Components */
import './App.css';
import ScrollToTop from './ScrollToTop.js';
import Price from './Price/Price.js';
import Menus from './Menus/Menus.js';
import Banner from './Banner/Banner.js';
import Vib from './VIB/Vib.js';
import Offers from './Offers/Offers.js';
import Footer from './Footer/Footer.js';
import Testimonials from './Testimonials/Testimonials.js';
import Separator from './Separator/Separator.js';
import Faq from './FAQ/FAQ.js';
import Contact from './Contact/Contact.js';
import Privacy from './Privacy/Privacy.js';
import Conditions from './Conditions/Conditions.js';
import Policies from './Policies/Policies.js';
//import Checkout from './Checkout/Checkout.html';
//import PaymentProcessor from './Checkout/payment_processor.php';
import Whatsapp from './Whatsapp/Whatsapp.js';



/*Importing Images*/
import logus from './Images/logo.svg';
import facebook from './Images/Facebook.svg';
import insta from './Images/Instagram.svg';
import twitter from './Images/Twitter.svg';
import faqBanner from './Images/FAQ_Banner.png';
import contactsImg from './Images/contacts-men.png';
import phoneImg from './Images/phone.svg';
import mailImg from './Images/email.svg';
import locationImg from './Images/location.svg';
import hostingerImg from './Images/Hostinger.svg';
import visaImg from './Images/Visa.png';
import amexImg from './Images/amex.svg';
import masterImg from './Images/mastercard.svg';
import discoverImg from './Images/discover.svg';
import fingerprintImg from './Images/fingerprint2.svg';
import whatsappImg from "./Images/WhatsApp_Logo1.svg";
import whatsappImg2 from "./Images/WhatsApp_Logo2.svg";
import lock from "./Images/lock.svg";


let plan = "";
const plan1=[
  {planName: "Tech-Support Service"},
  {price: 199},
  {item1: "Tech-support"},
  {item2: "Text"},
  {item3: "Whatsapp"},
  {item4: "Skype"},
  {item5: "FaceTime"},
  {item6: "E-mail"}
];
const plan2=[
  {planName: "Marketing & Web Package"},
  {price: 799},
  {item1: "Web Design"},
  {item2: "Web Maintenance and alterations"},
  {item3: "Logo Design and Changes"},
  {item4: "Mobile Responsive Design"},
  {item5: "Professional Business e-mails"},
  {item6: "Source Files"},
  {item7: "Accounts Login Info"},
  {item8: "Complete deployment"},
  {item9: "Google Analytics and Feedback"},
  {item10: "Complete Source Files"},
  {item11: "Paper Stock Design"},
  {item12: "Social Media Marketing"},
  {item13: "Business Listings"},
  {item14: "Stock Images"},
  {item15: "Copywriting"},
  {item16: "Multilingual features"},
  {item17: "*** Website Upgrades ***"},
  {item18: "Advanced SEO"},
  {item19: "Online Payment Processing Integration"},
  {item20: "Custom Website Features"},
  {item21: "Unlimited Pages"}
];
const plan3 = [
    {planName: "Starter Package"},
    {price: 599},
    {item1: "Web Design"},
    {item2: "Web Maintenance and Alterations"},
    {item3: "Logo Design and Changes"},
    {item4: "Mobile Responsive Design"},
    {item5: "Professional Business e-mails"},
    {item6: "Source Files"},
    {item7: "Accounts Login Info"},
    {item8: "Google Analytics and Feedback"},
    {item9: "Complete Source Files"},
    {item10: "Paper Stock Design"},
    {item11: "Complete Deployment"}
  
];


const plan4={
  planName: "General Business Consulting",
  price: 885,
  details: [
  "No Appointment Needed, fast guidance when you need it most",
  "General Questions",
 "Digital Transformation",
 "Operational Efficiency",
 "Leadership Development and Executive Coaching",
 "Innovation and Product Development",
 "Strategic Planning",
 "*Calls and meetings are limited to 20 monthly hours, texting and email inquiries are unlimited"
]
};
const plan5={
  planName: "Summer Starter Pack",
  price: 1599,
  details: ["1 Year VIB Starter Pack", "$100 for printed marketing materials"],
};



class App extends React.Component {
constructor(props){
  super(props);
  this.state = {cxReview: "Fantastic value! After months of trying to get my online marketing "+
   "to work by myself they have accomplished what I could not. I love how communicative they are "+
   "and how they have been willing to educate me on technology and the processes that they have been going through to get things ready on the back-end.",
    cxName: "Henry Rodriguez - Miami, FL",
    
    cxReview2: "High quality work, inventive and did exactly what we needed. Will definitely work with Mixael Contreras again!",
    cxName2: "John Ford - New York City, NY",

    cxReview3: "While other companies where charging me a lot of money they would not bring me any new clients and they did not showcase our online presence " + 
    "as I wanted. Being able to focus on helping my customers while Mixael Contreras " +
    "focused on my image and marketing needs, is one of the best decisions I've ever made.",
    cxName3: "Roxy Nguyen - San Destin, FL",

    menuClass: "", menuText: "Menu",
   TopAni: "", MiddleAni: "", BottomAni: "", logoDinamic: "", currentCard: "", currentPlan: "",
  text: "Checkout Now", loaded: "false"}
}


changeStates=(name,value)=>{
  this.setState({[name]: value});
}

componentWillMount(){
  
}


componentDidMount(){

}

componentWillUnmount() {
  /*clearInterval(this.stateID);*/
}
//props Start //////////////////
 testimonials={
   index: 0,
   testimonialNum: 1
 }
  clicks={
    clicked: "false"
  }
  classes={
    menuClass: ""
  }
  review={
    cxReview: "test"
  }
  images={
    logo:[
      {path: logus},
      {hostinger: hostingerImg}
    ],
    social:[
      {face: facebook}
    ],
    faq:[
      {faqBanner: faqBanner}
    ],
    contacts:[
      {contactImg: contactsImg},
      {phone: phoneImg},
      {mail: mailImg},
      {location: locationImg}
      
    ],
    cards:[
      {visa: visaImg },
      {amex: amexImg },
      {master: masterImg },
      {discover: discoverImg },
      {logoDinamic: ""}
    ],
    banners:[
      {fingerprint: fingerprintImg}
    ]
  }
  offers={
    expDate:[
      {content: "08/31/2024"}
    ],
    coupon:[
      {content: "MYFIRST"}
    ]
  }
  global={
    status:[
      
    ]
  }
  text={
    required: "Test"
  }
  
    

  
  internalLinks ={
    links:[
      {VIB: 'plans'}
    ]
  }
  
  // status is either SUCCESS or FAILURE;
  

  
 /* cardLogoHandler=(input)=>{
    /*
        American Express :- Starting with 34 or 37, length 15 digits.
        Visa :- Starting with 4, length 13 or 16 digits.
        MasterCard :- Starting with 51 through 55, length 16 digits.
        Discover :- Starting with 6011, length 16 digits or starting with 5, length 15 digits.
        */
      /* if(input.currentTarget.value.match(/^4/)){
        //console.log("Visa");
        this.setState({logoDinamic: visaImg, currentCard: "visa"});
       }
       else if(input.currentTarget.value.match(/^34/) || input.currentTarget.value.match(/^37/)){
        //console.log("American Express");
        this.setState({logoDinamic: amexImg, currentCard: "American Express"});
        }

        else if(input.currentTarget.value.match(/^5/)){
           //console.log("MasterCard");
            this.setState({logoDinamic: masterImg, currentCard: "Mastercard"});
        }
        else if(input.currentTarget.value.match(/^6/)){
            //console.log("Discover");
            this.setState({logoDinamic: discoverImg , currentCard: "Discover"});
        }
        else{
          //console.log("None");
          this.setState({logoDinamic: null, currentCard: ""});
        }
  }
*/
 /* testerCheckout=(props)=>{
    
    for(let i = 2; i < props.length; i++){
      //console.log(props.length + " This is iteration: " + i);
      return(
          <div className="checkout-summary">
            <h3 className="checkout-summary-title">{props[0].planName}</h3>
            <ul className="checkout-summary-list">
          <li className="checkout-summary-list-items">{props[2].item1}</li>
          <li className="checkout-summary-list-items">{props[3].item2}</li>
          <li className="checkout-summary-list-items">{props[4].item3}</li>
          <li className="checkout-summary-list-items">{props[5].item4}</li>
          {props[6] ? <li className="checkout-summary-list-items">{props[6].item5}</li>:""}
          {props[7] ? <li className="checkout-summary-list-items">{props[7].item6}</li>:""}
          {props[8] ? <li className="checkout-summary-list-items">{props[8].item7}</li>:""}
          {props[9] ? <li className="checkout-summary-list-items">{props[9].item8}</li>:""}
          {props[10] ? <li className="checkout-summary-list-items">{props[10].item9}</li>:""}
          {props[11] ? <li className="checkout-summary-list-items">{props[11].item10}</li>:""}
          {props[12] ? <li className="checkout-summary-list-items">{props[12].item11}</li>:""}
          {props[13] ? <li className="checkout-summary-list-items">{props[13].item12}</li> :""}
          {props[14] ? <li className="checkout-summary-list-items">{props[14].item13}</li> :""}
          {props[15] ? <li className="checkout-summary-list-items">{props[15].item14}</li> :""}
          {props[16] ? <li className="checkout-summary-list-items">{props[16].item15}</li> :""}
          {props[17] ? <li className="checkout-summary-list-items">{props[17].item16}</li> :""}
          {props[18] ? <li className="checkout-summary-list-items"><br/>{props[18].item17}</li> :""}
          {props[19] ? <li className="checkout-summary-list-items"><br/>{props[19].item18}</li> :""}
          {props[20] ? <li className="checkout-summary-list-items">{props[20].item19}</li> :""}
          {props[21] ? <li className="checkout-summary-list-items">{props[21].item20}</li> : ""}
          </ul>
      <h1 className="checkout-price">Total<br/> ${props[1].price}.00/mo</h1>
        </div>
      );}}*/
planDetailsWriter =(plan)=>{
  let allDetails = [];
  for(let i=0; i < plan.details.length; i++){
    allDetails.push(<h3 className="offers-endStatement" key={i}>{plan.details[i]}</h3>);
    
    console.log(allDetails);
  }
  return allDetails;
}
    testimonialHandler=(props)=>{
      this.testimonials.index--;
      //console.log(this.testimonials.testimonialNum);
      if(this.testimonials.testimonialNum > 3){
        this.testimonials.testimonialNum = 1;
      }

      if(props == 'right') {
        
        let testi = document.getElementById('Testimonial'+ this.testimonials.testimonialNum);
        testi.classList.add('slideOutRAni');
        this.testimonials.testimonialNum++;
        const constantIndex = this.testimonials.index;
        
        setTimeout(()=>{
          testi.style.zIndex = constantIndex;
          testi.classList.remove('slideOutRAni');
        },1000);

      
    
    }
    else{
      
      let testi = document.getElementById('Testimonial'+ this.testimonials.testimonialNum);
      testi.classList.add('slideOutLAni');
      this.testimonials.testimonialNum++;
      const constantIndex = this.testimonials.index;
      setTimeout(()=>{
        testi.style.zIndex = constantIndex;
        testi.classList.remove('slideOutLAni');
      },1000);
      //console.log("left clicked");
    }
      
      
  }
  menuShow = ()=>{//Display Lateral menu and hides by changing states
    if(this.clicks.clicked === "false"){
    this.setState({menuClass: "side_Menu-on", clicked: "true", menuText: "Close",
     TopAni: "TopBarAnimationOn", MiddleAni: "MiddleBarAnimationOn",
      BottomAni: "bottomBarAnimationOn"});
    this.clicks.clicked = "true";
}
    else if(this.clicks.clicked === "true"){
      this.setState({menuClass: "side_Menu-off",clicked: "false", menuText: "Menu", TopAni: "", MiddleAni: "", BottomAni: ""});
      this.clicks.clicked = "false";
  }
}
checkoutInput(props){// Display the plan items at checkout
  if(props == 199){
    plan = plan1;
  }
  else if(props == 799){
    plan = plan2;
  }

  else if(props == 599){
    plan = plan3;
  }
  else{
    alert("An Error has occurred, please select one of the available plans");
  }
}

scrollToAnchor(property){
  //console.log(property);
  if(property != null){
  let y = document.getElementById(property).offsetTop;

  return window.scrollTo(0,document.getElementById(property).offsetTop);
  }
}
 
/*<Price currentPrice={this.prices.low[0].content}></Price>*/
render(){
  console.log(process.env.REACT_APP_NAME);
  return (
    
    <BrowserRouter>
    <ScrollToTop>
      <Switch>
        
 
        

        <Route path="/" exact={true} render={() =>  (
          <div className="App">
            <Menus logo={this.images.logo[0].path} menu={this.state.menuText} menuHandler={this.menuShow} x={this.state.menuClass} TopBarAnimation={this.state.TopAni} MiddleBarAnimation={this.state.MiddleAni} BottomBarAnimation={this.state.BottomAni} lockImg={lock}></Menus>
            <Banner link={() => this.scrollToAnchor("plans")}></Banner>
            <Separator link={this.internalLinks.links[0].VIB}></Separator>
            <Vib hostingerImg={this.images.logo[1].hostinger} CheckoutInput={this.checkoutInput}></Vib>
            <Separator></Separator>
            <Testimonials TestimonialHandler={this.testimonialHandler} review={this.state.cxReview} cxName={this.state.cxName} review2={this.state.cxReview2} cxName2={this.state.cxName2} review3={this.state.cxReview3} cxName3={this.state.cxName3}></Testimonials>
            <Separator></Separator>
            {/*<Offers expDate={this.offers.expDate[0].content} planName={plan5.planName} planPrice={plan5.price} details={this.planDetailsWriter(plan5)} coupon={this.offers.coupon[0].content} link={() => this.scrollToAnchor("plans")}></Offers>*/}
            <Offers expDate="" planName={plan4.planName} planPrice={plan4.price} details={this.planDetailsWriter(plan4)}></Offers>
            <Footer logo={this.images.logo[0].path} face={this.images.social[0].face} visaImg={this.images.cards[0].visa} amexImg={this.images.cards[1].amex} masterImg={this.images.cards[2].master} discoverImg={this.images.cards[3].discover}></Footer>
            <Whatsapp whatsappImg={whatsappImg2}></Whatsapp>
            
          </div>
        )}/>

        <Route path="/FAQ/FAQ.js" exact={true} render={() =>  (
        <div>
          <Menus logo={this.images.logo[0].path} menu={this.state.menuText} menuHandler={this.menuShow} x={this.state.menuClass} TopBarAnimation={this.state.TopAni} MiddleBarAnimation={this.state.MiddleAni} BottomBarAnimation={this.state.BottomAni} lockImg={lock}></Menus>
          <Faq faqBanner={this.images.faq[0].faqBanner}></Faq>
          <Footer logo={this.images.logo[0].path} face={this.images.social[0].face} visaImg={this.images.cards[0].visa} amexImg={this.images.cards[1].amex} masterImg={this.images.cards[2].master} discoverImg={this.images.cards[3].discover} ></Footer>
          <Whatsapp whatsappImg={whatsappImg2}></Whatsapp>
        </div>

        )}/>

        <Route path="/Contact/Contact.js" exact={true} render={() =>  (
          <div>
          <Menus logo={this.images.logo[0].path} menu={this.state.menuText} menuHandler={this.menuShow} x={this.state.menuClass} TopBarAnimation={this.state.TopAni} MiddleBarAnimation={this.state.MiddleAni} BottomBarAnimation={this.state.BottomAni} lockImg={lock}></Menus>
          <Contact contactsImg={this.images.contacts[0].contactImg} phoneImage={this.images.contacts[1].phone} emailImage={this.images.contacts[2].mail} locationImage={this.images.contacts[3].location}></Contact>
          <Footer logo={this.images.logo[0].path} face={this.images.social[0].face} visaImg={this.images.cards[0].visa} amexImg={this.images.cards[1].amex} masterImg={this.images.cards[2].master} discoverImg={this.images.cards[3].discover}></Footer>
          <Whatsapp whatsappImg={whatsappImg2}></Whatsapp>
          </div>
        )}/>

       <Route path="/Privacy/Privacy.js" exact={true} render={() => (
         <div>
            <Menus logo={this.images.logo[0].path} menu={this.state.menuText} menuHandler={this.menuShow} x={this.state.menuClass} TopBarAnimation={this.state.TopAni} MiddleBarAnimation={this.state.MiddleAni} BottomBarAnimation={this.state.BottomAni} lockImg={lock}></Menus>
            <Privacy anchorHandle={this.scrollToAnchor} fingerprint={this.images.banners[0].fingerprint}></Privacy>
            <Footer logo={this.images.logo[0].path} face={this.images.social[0].face} visaImg={this.images.cards[0].visa} amexImg={this.images.cards[1].amex} masterImg={this.images.cards[2].master} discoverImg={this.images.cards[3].discover}></Footer>
            <Whatsapp whatsappImg={whatsappImg2}></Whatsapp>
         </div>
       )}/>

       <Route path="/Policies/Policies.js" exact={true} render={()=>(
         <div>
           <Menus logo={this.images.logo[0].path} menu={this.state.menuText} menuHandler={this.menuShow} x={this.state.menuClass} TopBarAnimation={this.state.TopAni} MiddleBarAnimation={this.state.MiddleAni} BottomBarAnimation={this.state.BottomAni} lockImg={lock}></Menus>
           <Whatsapp whatsappImg={whatsappImg2}></Whatsapp>
         </div>
       )}/>

       <Route path="/Conditions/Conditions.js" exact={true} render={()=>(
         <div>
          <Menus logo={this.images.logo[0].path} menu={this.state.menuText} menuHandler={this.menuShow} x={this.state.menuClass} TopBarAnimation={this.state.TopAni} MiddleBarAnimation={this.state.MiddleAni} BottomBarAnimation={this.state.BottomAni} lockImg={lock}></Menus>
          <Conditions></Conditions>
          <Footer logo={this.images.logo[0].path} face={this.images.social[0].face} visaImg={this.images.cards[0].visa} amexImg={this.images.cards[1].amex} masterImg={this.images.cards[2].master} discoverImg={this.images.cards[3].discover}></Footer>
          <Whatsapp whatsappImg={whatsappImg2}></Whatsapp>
         </div>
       )}/>

        {/*<Route path="/Checkout/Checkout.html" exact={true} render={()=>(
          
          <div>
            
            <Checkout /*planItems={this.testerCheckout(plan)} planPrice={plan[1].price} logoHandler={this.cardLogoHandler} chosenPlan={plan[0].planName} cardPicture={this.state.logoDinamic} currentCard={this.state.currentCard} requiredText={this.state.text} *//*card={this.state.currentCard} paymentForm={ window.SqPaymentForm } handleSubmission={async ()=> {await this.handlePaymentMethodSubmission}} paymentResults={async ()=> {await this.displayPaymentResults()}}*//* stateChange={this.changeStates}*//*></Checkout>
            <Whatsapp whatsappImg={whatsappImg2}></Whatsapp>
          </div>
        )}/>*/}

        {/*<Route path="/Checkout/payment_processor.php" exact={true} render={()=>(
          <div>
            <PaymentProcessor></PaymentProcessor>
          </div>
        )}/>*/}
      </Switch>
      </ScrollToTop>
    </BrowserRouter>
       
      
    );
  }
  
  
}



export default App;