import React from 'react';

const testimonials = (props) =>{
    return (
        <div className="testimonials">
            <div id="LeftArrow" onClick={props.TestimonialHandler}><div className="arrow left"></div></div>
            {/*TESTIMONIAL 1 STARTS*/}
            <div id="Testimonial1" className="testimonials-Wrapper1">
               <div className="testimonials-image1"><div className="testimonials-image1-child"></div></div>
                <div className="testimonials-review1">
                    <h2 className="testimonials-title">What People are Saying</h2>
                    <br></br>
                    <h3 className="testimonials-review">{props.review}</h3>
                    <h5 className="testimonials-name">{props.cxName}</h5>
                </div>
            </div>

            {/*TESTIMONIAL 2 STARTS*/}

            <div id="Testimonial2" className="testimonials-Wrapper2">
               <div className="testimonials-image2"><div className="testimonials-image2-child"></div></div>
                <div className="testimonials-review2">
                    <h2 className="testimonials-title">What People are Saying</h2>
                    <br></br>
                    <h3 className="testimonials-review">{props.review2}</h3>
                    <h5 className="testimonials-name">{props.cxName2}</h5>
                </div>
            </div>
            {/*TESTIMONIAL 3 STARTS*/}

            <div id="Testimonial3" className="testimonials-Wrapper3">
               <div className="testimonials-image3"><div className="testimonials-image3-child"></div></div>
                <div className="testimonials-review3">
                    <h2 className="testimonials-title">What People are Saying</h2>
                    <br></br>
                    <h3 className="testimonials-review">{props.review3}</h3>
                    <h5 className="testimonials-name">{props.cxName3}</h5>
                </div>
            </div>
            <div id="RightArrow" onClick={()=>{props.TestimonialHandler("right");}}><div className="arrow right"></div></div>
        </div>
    );
}

export default testimonials;