import React, {Component} from 'react';
import { render } from '@testing-library/react';
import {BrowserRouter as Router,Link, Switch,Route} from 'react-router-dom';
import Faq from '../FAQ/FAQ.js';
import App from '../App.js';


    
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    //console.log(year);



const footer = (props) =>{

    return(
        <footer className="foot">
            <p className="foot-text">Follow Us</p>
            <div className="foot-social">
               
                    <a className="foot-social Face" target="blank" href="https://www.facebook.com/Mixael-Contreras-Corp-107950097507842/"></a>
                    <a className="foot-social Insta" target="blank" href="https://www.instagram.com/mixael_contreras/"></a>
                    <a className="foot-social In" target="blank" href="https://www.linkedin.com/company/mixael-contreras/"></a>
                    <a className="foot-social Twit" target="blank" href="https://twitter.com/Code_Web_Design"></a>
               
            </div>
            <div className="creditcards-wrapper">
                <p>We Proudly Accept</p>
                <div className="creditcards-group">
                    <div className="visa"><img className="visa-pic" src={props.visaImg} alt="Visa"/></div>
                    <div className="amex"><img className="amex-pic" src={props.amexImg} alt="Amex"/></div>
                    <div className="master"><img className="master-pic" src={props.masterImg} alt="Master Card"/></div>
                    <div className="discover"><img className="discover-pic" src={props.discoverImg} alt="Discover"/></div>                    
                </div>
            </div>

            <img className="foot-logo" src={props.logo}></img>
            <div className="foot-copy"><p>Copyright © <year id="date">{year}</year> Mixael Contreras Corp.</p></div>
                <div className="foot-link-wrap">
                    <div className="foot-bottom-left">
                        <Link className="foot-links" to={'/FAQ/FAQ.js'}>FAQs</Link>
                        <Link className="foot-links" to={'/Privacy/Privacy.js'}>Privacy Policy</Link>
                        <Link className="foot-links" to={'/Conditions/Conditions.js'}>Terms and Conditions</Link>
                        {/*<Link className="foot-links" to={'/Policies/Policies.js'}>Company Policies</Link>*/}
                    </div>
                
                    <div className="foot-bottom-right">
                        {/*<a className="foot-links" href="#">Learn</a>*/}
                        <Link className="foot-links" to="/Contact/Contact.js">Contact</Link>
                        {/*<a className="foot-links" href="#">Careers</a>*/}
                    </div>
                </div>
            
        </footer>
        );
    
}

export default footer;